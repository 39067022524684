import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AlightContactsComponent } from '../modals/alight-contacts/alight-contacts.component';
import { NavigationResponse } from '../../core/models/navigation-response';
import { NavigationService } from '../../shared/navigation/navigation.service';
import { NavigationModel } from '../../core/models/navigation-model';
import { NavigationStateType } from '../../core/enums/NavigationStateType';
import { ContactsService } from '../../core/services/contacts.service';
import { ContactsResponse } from '../../core/models/contacts-response';
import { ContactsModel } from '../../core/models/contacts-model';
import { AccountService } from '../../core/services/account.service';
import { Member } from '../../core/models/login/member';
import { SitePaths } from '../../core/models/sitePaths';
import { SitePathsResponse } from '../../core/models/sitePathsResponse';

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss']
})
export class LeftNavComponent implements OnInit {
  member: Member;
  firstName: string;
  nameParts: string[];
  ref: DynamicDialogRef;
  commands: object;
  sitePaths: SitePaths;
  enableHrAdmin: boolean;
  hrAdminRoute:string;

  constructor(
    public dialogService: DialogService,
    private navigationService: NavigationService,
    private router: Router,
    private contactsService: ContactsService,
    private accountService: AccountService ) {
      this.member = this.accountService.getCurrentMember();

      this.commands = {
        logout: (event) => { this.logout(); },
        displayContact: (event) => { this.displayContact(); },
        profile: (event) => { this.profile(); },
        goToAdmin: (event, queryParam) => {this.goToAdmin(queryParam);},
        thoughtLeadership: (event) => {this.thoughtLeadership(); }
      }

    this.navigationService.GetSitePaths().subscribe((data: SitePathsResponse) => {
      this.sitePaths = data.data;
    });
  }

  items: MenuItem[] = [];
  quickactions: MenuItem[] = [];
  lowerNav: MenuItem[] = [];
  contacts: ContactsModel[];

  private topItems: NavigationModel[];
  private quickItems: NavigationModel[];
  private lowerItems: NavigationModel[];

  ngOnInit(): void {

    this.navigationService.GetLeftNavigation().subscribe((data: NavigationResponse) => {
      this.topItems = data.items.filter((mi: NavigationModel) => mi.name == 'Top');
      this.nameParts = this.member.fullName.split(" ");
      this.firstName = this.nameParts[0];
      this.items = this.topItems[0].items as MenuItem[];
      this.items[0].items[0]. label = this.firstName;
      this.items = this.findState(this.items)

      this.quickItems = data.items.filter((mi: NavigationModel) => mi.name === 'QuickLinks');
      this.quickactions = this.quickItems[0].items as MenuItem[];
      this.quickactions = this.findState(this.quickactions);

      this.lowerItems = data.items.filter((mi: NavigationModel) => mi.name === 'Lower');
      this.lowerNav = this.lowerItems[0].items as MenuItem[];
      this.lowerNav = this.findState(this.lowerNav);
    });

    this.contactsService.GetContacts().subscribe((data: ContactsResponse) => {
      this.contacts = data.data ;
    });
  }

  displayContact() {
    this.ref = this.dialogService.open(AlightContactsComponent, {
    data: this.contacts,
    modal:true});
  }

  logout() {
    this.goToAdmin(['Account/Logoff'])
  }

  thoughtLeadership() {
    window.open("https://www.alight.com/thought-leadership");
  }

 profile() {
  this.router.navigate(['/profile']);
 }

  findState(items) {
    var stateArr = items.map(item => {
      if (item.items.length > 0) {
        this.findState(item.items);
      } else if (item.state) {
        item.state.map(state => {
          if (state.type == NavigationStateType.Command) {
            // state.data can contain different or no elements depending on the contents of state.name
            // if new / different elements are added to state.name corresponding changes must be added below
            // identical changes must be made to the same method in the navigation component
            if (state.data) {
              let menuRoute = state.data.route;
              item.command = (event) => { this.commands[`${state.name}`](event, menuRoute) };
            } else {
              item.command = (event) => { this.commands[`${state.name}`](event) };
            }
          }
        })
      }
      return item;
    });
    return stateArr;
  }

  goToAdmin(menuRoute:[k:string]) {
    this.hrAdminRoute = "" + menuRoute;
    this.enableHrAdmin = true;
  }
}
