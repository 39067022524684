<div class="timeout-modal">
  <div class="logout-modal__controls">
    <p><strong>You've been inactive for a while. Your session is about to end.</strong></p>
    <p-card [style]="{width: '400px'}">
      <h1>{{counter | formatTime}}</h1>
    </p-card>
    <br/>
    <div class="buttons">
      <p-button icon="pi pi-check" pRipple type="button" label="Continue" (click)="resetTimer()" styleClass="p-button-rounded"></p-button>
      <p-button pRipple type="button" label="Log out" (click)="logout()" styleClass="p-button__secondary"></p-button>
    </div>
  </div>
</div>
<app-goto-admin [hrAdminRoute]="hrAdminRoute" *ngIf="enableHrAdmin"></app-goto-admin>
