import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [DatePipe]
})
export class FooterComponent implements OnInit {
  currentYear: string;
  copyrightYear: string;

  constructor(private dataPipe: DatePipe) {
   }

  ngOnInit(): void {
    this.currentYear = this.dataPipe.transform((new Date), 'yyyy');
    this.copyrightYear = new Date().getFullYear().toString();
  }

}
