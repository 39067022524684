<div class="content">
  <div class="column column-primary" id="main-login-form">
    <section class="component primary-component">
      <article class="component-content">
        <form [formGroup]="loginForm" (ngSubmit)="login()">
          <p-card id="login" class="primary-login">

            <div class="login-error" *ngIf="validationErrors.length == 1">
              <p *ngFor="let error of validationErrors" class="form-error">{{error}}</p>
            </div>

            <div class="login-error" *ngIf="validationErrors.length > 1">
              <p *ngFor="let error of validationErrors" class="form-error">{{error}}</p>
            </div>

            <div class="">
              <span class="p-float-label">
                <input class="form-control__input" type="password" required formControlName='username' pInputText>
                <label for="usernameId">Username</label>
                <p-message severity="error" text="Please enter username" *ngIf="loginForm.get('username')?.errors && loginForm.get('username')?.touched"></p-message>
              </span>
            </div>

            <div class="">
              <span class="p-float-label" id="login-password">
                <input class="form-control__input" type="password" required formControlName='password' pInputText>
                <label for="passwordId">Password</label>
                <p-message severity="error" text="Please enter password" *ngIf="loginForm.get('password')?.errors && loginForm.get('password')?.touched"></p-message>
              </span>
            </div>

            <div class="button-container">
              <div id="login-btn">
                <button id="login_button" type="submit" pButton label="Submit" class="p-button" pRipple [disabled]='!loginForm.valid'></button>
              </div>
            </div>

          </p-card>
        </form>
      </article>
    </section>
  </div>
</div>
