export class OEPeriodList {
  oePeriods: Array<OEPeriod> = [];
}

export class OEPeriod {
  displayDate: Date
  enrollPeriodAlerts: Array<OEPeriodAlert> = [];
  enrollPeriodID: number;
  name: string;
}

export class OEPeriodAlert {
  header: string;
  count: number;
  cssClass: string;
  description: string;
  linkURL: string;
  downloadName: string;
  modalID: string;
  id: number;
  announcementsLastUpdate: string;
  releaseNotesLastUpdate: string;
  referenceMaterialsLastUpdate: string;
  epaType: number;
  displayEpaType: number;
}
