import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  constructor(private primengConfig: PrimeNGConfig,
              private messageService: MessageService) {
    this.primengConfig = primengConfig;
  }
  title = 'Employer Portal';

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

}
