import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IdleUserService } from '../../../core/services/idle-user.service';
import { Member } from '../../../core/models/login/member';
import { AccountService } from '../../../core/services/account.service';
import { environment } from '../../../../environments/environment';
import { SiteDetailsResponse } from '../../../core/models/login/siteDetailsResponse';
import { SiteDetails } from '../../../core/models/login/siteDetails';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({});
  member: Member;
  returnUrl: string;
  validationErrors: string[] = [];
  credentialsInvalid: string;
  token: string;
  timeOut: number;
  enableHrAdmin: boolean;
  siteDetailResponse: SiteDetailsResponse;
  siteDetails: SiteDetails;

  constructor(private fb: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private accountService: AccountService,
              private idleUser: IdleUserService) {
                this.siteDetailResponse = new SiteDetailsResponse();
                this.siteDetailResponse = this.route.snapshot.data['siteDetails'];
                this.siteDetails = new SiteDetails()
                this.siteDetails = this.siteDetailResponse.SiteDetails;
              }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.token = this.route.snapshot.queryParams['jwtToken'];

    this.loginForm = this.fb.group({
      username: ['',[Validators.required]],
      password: ['',[Validators.required]],
      accessToken:['']
    });
    
    if (this.token) {
      this.loginForm.controls['username'].setValue('jwtLogin');
      this.loginForm.controls['password'].setValue('jwtLogin');
      this.loginForm.controls['accessToken'].setValue(this.token);
      this.login();
    } else {
      this.accountService.clearUserSession();
    }
  }

  login() {
    this.accountService.login(this.loginForm.value, this.siteDetails.SiteID).subscribe(x => {
      this.timeOut = environment.idleTimeout;
      this.idleUser.startWatching(this.timeOut);
      let redirect = sessionStorage.getItem('redirectUrl');
      sessionStorage.setItem('redirectUrl', '');      

      this.accountService.checkIfPwdExpired().toPromise().then(
        response => {

          if (response)
          {
            this.checkPwdExpireCallbackRedirect('/profile', 'Profile');
          }
          else
          {
            if (this.returnUrl !== null && this.returnUrl !== '/')
            {
              this.checkPwdExpireCallbackRedirect(redirect ?? this.returnUrl, this.returnUrl);
            }
            else
            {
              this.checkPwdExpireCallbackRedirect('/home', 'Home');
            }            
          }
          sessionStorage.setItem('username', this.loginForm.get('username').value);
        },
        (error) => {
          console.log('Error while checking if password expired');
          console.log(error);
        });       

      
    }, error => {
      let message = error.error.Message;
      if (error.error.error == "invalid_client") {
        message = "Credentials Invalid. Please verify your credentials and try again or reset your password.";
      }
      this.validationErrors = [message];
    })
  }

  private checkPwdExpireCallbackRedirect(internalRedirectRoute: string, previousUrl: string) {
    this.member = this.accountService.getCurrentMember();
    let ufpCookieAuthDisabled = this.accountService.isUFPCookieAuthDisabled();

    if (!this.token && !ufpCookieAuthDisabled) {
      this.accountService.getSiteDetails().toPromise().then((data: SiteDetailsResponse) => {
        let siteDetails = data.SiteDetails;
        let loginProxyUrl = (!document.location.href.includes("localhost:4200") ? siteDetails.AdminPath : environment.adminPath) + 'account/erportalproxylogin';

        this.router.navigate(["/"]).then(result => {
          let proxyLoginForm = document.createElement('form');
          proxyLoginForm.action = loginProxyUrl;
          proxyLoginForm.method = 'POST';

          let proxyLoginFormData = { returnUrl: internalRedirectRoute, token: this.member.token, refreshToken: this.member.refreshToken };
          for (let key in proxyLoginFormData) {
            let formInput = document.createElement('input');
            formInput.type = 'hidden';
            formInput.name = key;
            formInput.value = proxyLoginFormData[key];
            proxyLoginForm.appendChild(formInput);
          }

          document.body.appendChild(proxyLoginForm);
          proxyLoginForm.submit();
        });
      });
    }
    else {
      this.router.navigateByUrl(internalRedirectRoute);
    }
    sessionStorage.setItem('previousUrl', previousUrl);
  }

}
