export enum DashBoardLinks {
  None = 0,
  ShowIncompleteNewHires = 1 << 0,
  ShowDocumentsNeedingApproval = 1 << 1,
  ShowOpenDocumentRequest = 1 << 2,
  ShowEEsWithPendingLifeEvents = 1 << 3,
  ShowDependentsNearAgeLimit = 1 << 4,
  ShowDependentsPastAgeLimit = 1 << 5,
  ShowEEsWaitingEOIFollowUpApproval = 1 << 6,
  ShowLockedOutEmployees = 1 << 7,
  ShowIncompleteACAEnrollments = 1 << 8,
  ShowCompleteOEEnrollments = 1 << 9,
  ShowInProcessOEEnrollments = 1 << 10,
  ShowNotStartedOEEnrollments = 1 << 11,
  ShowNewHireWindowIncompleteOE = 1 << 12,
  ShowNewHirePending = 1 << 13,
  ShowPendingOECarryOver = 1 << 14,
  ShowBirthdays = 1 << 15,
  ShowHiredateAnniversary = 1 << 16,
  ShowACAEligible = 1 << 17,
  All = ~(-1 << 18),
  ShowDemoSiteLinks = 1 << 19 // Temporary flag to show non-functioning dashboard links on demo sites
}
