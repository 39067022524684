import { Component } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ContactsModel } from '../../../core/models/contacts-model';

@Component({
  selector: 'app-alight-contacts',
  templateUrl: './alight-contacts.component.html',
  styleUrls: ['./alight-contacts.component.scss']
})
export class AlightContactsComponent {

  contacts: ContactsModel[];
  contactCount: number;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig) {
      this.contacts = config.data.contacts;
      this.contactCount = (this.contacts) ? this.contacts.length : 0;
    }

  closeModal() {
    this.ref.close();
  }

}
