<div class="login-error" *ngIf="validationErrors?.length > 0">
  <span class="text-danger text-wrapper validator">Unlock Account / Change Employee Password was unsuccessful</span>
  <ul class="text-danger validator">
    <li *ngFor="let error of validationErrors">{{error}}</li>
  </ul>
</div>

<div class="field-checkbox">
  <p-checkbox [(ngModel)]="isLocked" [binary]="true" inputId="binary" label="Account Locked Out"></p-checkbox>
</div>

<div class="p-float-label dep-ben__pass restrict-input-width">
  <p-password [(ngModel)]="newPassword" type="password" aria-required="true" [toggleMask]="true" required autocomplete="false" ></p-password>
  <label for="newPassword">New Password</label>
  <div class="input__error__msg">This is a required field</div>
</div>

<div class="p-float-label dep-ben__pass restrict-input-width">
  <p-password [(ngModel)]="confirmPassword" type="password" aria-required="true" [toggleMask]="true" required autocomplete="false" ></p-password>
  <label for="confirmPassword">Confirm Password</label>
  <div class="input__error__msg">This is a required field</div>  
</div>

<p class="text-sm">Under normal circumstances, the employee can change the account password. In event that the employee forgets the account password, you may change it here. Upon changing the password, the account will be updated and marked to force the employee to change the password to something new upon successful login. This will ensure that the employee's account information is held in privacy.</p>

<div class="align-right button-container">
  <p-button  (click)="savePassword()" label="Save" styleClass="p-button-sm" pRipple></p-button>
  <p-button  (click)="cancelPassword()" label="Cancel" styleClass="p-button__secondary" pRipple></p-button>
</div>
