import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry, map } from 'rxjs/operators';
import { PasswordRequest } from '../../../core/models/passwordRequest';
import { Member } from '../../../core/models/login/member';
import { AccountService } from '../../../core/services/account.service';
import { PasswordResponse } from '../../../core/models/passwordResponse';
import { TwoFaSettings } from '../../../core/models/TwoFaSettings';
import { TwoFaSettingsResponse } from '../../../core/models/TwoFaSettingsResponse';
import { PersonSecurityProfileResponse } from 'src/app/core/models/personSecurityProfileResponse';
import { UserTwoFaEnabledRequest } from 'src/app/core/models/userTwoFaEnabledRequest';
import { BaseSettings } from 'src/app/core/models/baseSettings';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  member: Member;
  responseMsg: string

  constructor(private http: HttpClient, private accountService: AccountService) {
    this.member = this.accountService.getCurrentMember();
  }

  GetTwoFa(): Observable<PersonSecurityProfileResponse> {
    return this.http.get<PersonSecurityProfileResponse>(this.member.apiPath + 'twofactor/getusersecurityprofile').pipe(retry(1));
  }

  ChangeMyPassword(passwordRequest: PasswordRequest): Observable<PasswordResponse> {
    return this.http.post<PasswordResponse>(this.member.apiPath + 'sitesetup/useradmin/changemypassword', JSON.stringify(passwordRequest))
    .pipe(retry(1),
      map((response: PasswordResponse) => {
        return response;
      }));
  }

  ValidatePassword(passwordRequest: PasswordRequest) {
    this.responseMsg = undefined;
    let messageArray: string[] = [];

    if (passwordRequest.OldPassword === undefined || passwordRequest.OldPassword === "") {
      if (this.responseMsg === undefined) {
        this.responseMsg = "'Old Password' is required.";
      }
    }

    if (passwordRequest.NewPassword === undefined || passwordRequest.NewPassword === "") {
      if (this.responseMsg === undefined) {
        this.responseMsg = "'New Password' is required."
      } else {
        this.responseMsg = this.responseMsg + ", 'New Password' is required."
      }
    }

    if (passwordRequest.ConfirmPassword === undefined || passwordRequest.ConfirmPassword === "") {
      if (this.responseMsg === undefined) {
        this.responseMsg = "'Confirm Password' is required."
      } else {
        this.responseMsg = this.responseMsg + ", 'Confirm Password' is required."
      }
    }

    if (passwordRequest.NewPassword !== passwordRequest.ConfirmPassword) {
      if (this.responseMsg === undefined) {
        this.responseMsg = "'New Password' and 'Confirm Password' do not match."
      } else {
        this.responseMsg = this.responseMsg + ", 'New Password' and 'Confirm Password' do not match."
      }
    }

    if (passwordRequest.NewPassword === passwordRequest.OldPassword) {
      if (this.responseMsg === undefined) {
        this.responseMsg = "'New Password' and 'Old Password' must be different."
      } else {
        this.responseMsg = this.responseMsg + ", 'New Password' and 'Old Password' must be different."
      }
    }

    if (this.responseMsg !== undefined && this.responseMsg !== "") {
      messageArray = this.responseMsg.split(',');

      messageArray.forEach((element, index) => {
        if (element === undefined) messageArray.splice(index, 1);
      });

      return messageArray;
    }
  }

  CheckMyPassword(myPassword: string): Observable<PasswordResponse> {
    return this.http.post<PasswordResponse>(this.member.apiPath + 'sitesetup/useradmin/checkmypassword', JSON.stringify(myPassword))
    .pipe(retry(1),
      map((response: PasswordResponse) => {
        return response;
      }));
  }

  SetUserTwoFactorEnabled(request: UserTwoFaEnabledRequest) {
    return this.http.post<BaseSettings>(this.member.apiPath + 'twofactor/setusertwofactorenabled', JSON.stringify(request))
      .pipe(retry(1),
      map((response: BaseSettings) => {
        return response;
      }));
  }

  SaveEmail(saveEmail: TwoFaSettings): Observable<TwoFaSettingsResponse> {
    return this.http.post<TwoFaSettingsResponse>(this.member.apiPath + 'twofactor/saveemail', JSON.stringify(saveEmail))
      .pipe(retry(1),
      map((response: TwoFaSettingsResponse) => {
        return response;
      }));
  }

  RemoveEmail(): Observable<TwoFaSettingsResponse> {
    return this.http.post<TwoFaSettingsResponse>(this.member.apiPath + 'twofactor/removeemail', null)
      .pipe(retry(1),
      map((response: TwoFaSettingsResponse) => {
        return response;
      }));
  }

  BeginSavePhone(savePhone: TwoFaSettings): Observable<TwoFaSettingsResponse> {
    return this.http.post<TwoFaSettingsResponse>(this.member.apiPath + 'twofactor/beginsavephone', JSON.stringify(savePhone))
      .pipe(retry(1),
      map((response: TwoFaSettingsResponse) => {
        return response;
      }));
  }

  EndSavePhone(savePhone: TwoFaSettings): Observable<TwoFaSettingsResponse> {
    return this.http.post<TwoFaSettingsResponse>(this.member.apiPath + 'twofactor/endsavephone', JSON.stringify(savePhone))
      .pipe(retry(1),
      map((response: TwoFaSettingsResponse) => {
        return response;
      }));
  }

  RemovePhone(): Observable<TwoFaSettingsResponse> {
    return this.http.post<TwoFaSettingsResponse>(this.member.apiPath + 'twofactor/removephone', null)
      .pipe(retry(1),
      map((response: TwoFaSettingsResponse) => {
        return response;
      }));
  }
}
