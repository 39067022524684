import { Component, OnInit } from '@angular/core';
import { MenuItem, SelectItem } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { Member } from '../../../core/models/login/member';
import { AccountService } from '../../../core/services/account.service';
import { PlansResponse } from '../../../core/models/plans-response';
import { PlanTiersResponse } from '../../../core/models/planTiers-response';
import { PlansModel } from '../../../core/models/plans-model';
import { PlanTiersModel } from '../../../core/models/plan-tiers-model';
import { OptGroupsModel } from '../../../core/models/opt-groups-model';
import { MultiCircleGraph } from '../../../../../src/assets/js/MultiCircleGraph.js';
import { ToDoResponse } from '../../../core/models/toDosResponse';
import { OEPeriodList, OEPeriodAlert, OEPeriod } from '../../../core/models/OEPeriods';
import { ToDoAlerts, Alert } from '../../../core/models/toDoAlerts';
import { ThoughtLeadershipResponse } from '../../../core/models/thoughtLeadershipResponse';
import { environment } from '../../../../environments/environment';
import { IdleUserService } from '../../../core/services/idle-user.service';

interface DataArray {
  title: string;
  value: number;
}

interface DataPieChart {
  eeCost: number;
  erCost: number;
  eeCovered: number;
  spouseCovered: number;
  childCovered: number;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  model: ToDoResponse;
  toDoList: ToDoAlerts = new ToDoAlerts();
  alert: Alert;
  oePeriodList: OEPeriodList = new OEPeriodList();
  thoughtLeadershipData: ThoughtLeadershipResponse = new ThoughtLeadershipResponse();
  timeOut: number = environment.idleTimeout;
  items: MenuItem[];
  home: MenuItem;
  member: Member;
  plansresponse: SelectItem[];
  plans: PlansModel[];
  planTiers: PlanTiersModel[];
  optgroups: OptGroupsModel[];
  dataArray: DataArray[];
  groupName: string;
  status: string;
  benefitPlanID: string;
  optGroupNum: string;
  selectedPlans;
  selectedOptionGroups;
  optionGroupsVisible: boolean = false;
  multiCircleVisible: boolean = true;
  totals: DataPieChart[];
  eeCovered: string;
  spouseCovered: string;
  childCovered: string;
  firstName: string;

  constructor(private accountService: AccountService,
              private route: ActivatedRoute,
              private idleUser: IdleUserService) {
    this.member = this.accountService.getCurrentMember();
    this.model = this.route.snapshot.data['toDos'];


    this.model.data.alerts.forEach(alert => {

      this.alert = new Alert();
      this.alert.announcementsLastUpdate = alert.announcementsLastUpdate ? alert.announcementsLastUpdate : "";
      this.alert.count = alert.count ? alert.count : 0;
      this.alert.description = alert.description ? alert.description : "";
      this.alert.downloadName = alert.downloadName ? alert.downloadName : "";
      this.alert.header = alert.header ? alert.header : "";
      this.alert.id = alert.id ? alert.id : 0;
      this.alert.linkURL = alert.linkURL ? alert.linkURL : "";
      this.alert.modalID = alert.modalID ? alert.modalID : "";
      this.alert.referenceMaterialsLastUpdate = alert.referenceMaterialsLastUpdate ? alert.referenceMaterialsLastUpdate : "";
      this.alert.releaseNotesLastUpdate = alert.releaseNotesLastUpdate ? alert.releaseNotesLastUpdate : "";
      this.alert.alertType = alert.alertType ? alert.alertType : 0;
      this.alert.cssClass = alert.cssClass ? alert.cssClass : "";
      this.alert.displayType = alert.displayType ? alert.displayType : 0;
      if (this.alert.count > 0) this.toDoList.Alerts.push(this.alert);

    });

    let result =  this.model.data.oePeriods.filter(oep => oep.enrollPeriodAlerts.length > 0 );

    result.forEach(oeperiodAlert => {

      let oePeriod: OEPeriod = new OEPeriod();
      oePeriod.enrollPeriodID = oeperiodAlert.enrollPeriodID;
      oePeriod.name = oeperiodAlert.name;
      oeperiodAlert.enrollPeriodAlerts.forEach(oepAlert => {

        let oePeriodAlert: OEPeriodAlert = new OEPeriodAlert();

        oePeriodAlert.header = oepAlert.header;
        oePeriodAlert.count = oepAlert.count;
        oePeriodAlert.cssClass = oepAlert.cssClass;
        oePeriodAlert.description = oepAlert.description;
        oePeriodAlert.linkURL = oepAlert.linkURL;
        oePeriodAlert.downloadName = oepAlert.downloadName;
        oePeriodAlert.modalID = oepAlert.modalID;
        oePeriodAlert.id = oepAlert.id;
        oePeriodAlert.announcementsLastUpdate = oepAlert.announcementsLastUpdate;
        oePeriodAlert.releaseNotesLastUpdate = oepAlert.releaseNotesLastUpdate;
        oePeriodAlert.referenceMaterialsLastUpdate = oepAlert.referenceMaterialsLastUpdate;
        oePeriodAlert.epaType = oepAlert.epaType;
        oePeriodAlert.displayEpaType = oepAlert.displayEpaType;

        oePeriod.enrollPeriodAlerts.push(oePeriodAlert);
      })

      this.oePeriodList.oePeriods.push(oePeriod);

    })

    this.dataArray = [];
    this.totals = [];
  }

  value10: number = 2500;
  text: string = "";

  ngOnInit(): void {

    this.idleUser.startWatching(this.timeOut);

    this.items = [{
      label: 'Analytics & Insights'

    }, {
      label: 'Annual Enrollment',
      items: [
        { label: 'AE Trends' },
        { label: 'Summary' },
        { label: 'Plan Migration' },
        { label: 'Reimbursement Accounts' },
        { label: 'Covered Population' },
        { label: 'Enrollment Choice' }
      ]
    }];

    this.home = { icon: 'pi pi-home' };


    this.groupName = "Health Insurance";
    this.status = "Current";
    this.accountService.GetPlans(this.groupName, this.status).subscribe((data: PlansResponse) => {
      this.plans = data.data.plans;

      this.benefitPlanID = "0";

      this.drawGraph("-1", "0");

    });

    if (this.member.fullName) {
      this.firstName = this.member.fullName.split(' ')[0];
    }

    this.thoughtLeadershipData = this.route.snapshot.data['thoughts'] as ThoughtLeadershipResponse;
  }

  groupChanged() {
    this.optionGroupsVisible = false;

    this.accountService.GetPlans(this.groupName, this.status).subscribe((data: PlansResponse) => {
      this.plans = data.data.plans;
      this.drawGraph("-1", "0");
      if (data.data.plans.length > 0) {
        this.benefitPlanID = "0";
      }
    });

  }

  createMultiCircle() {
    const cg2 = new MultiCircleGraph({
      target: 'circg_2',
      data_values: this.dataArray,
      inner_title: "",
      stroke_width: 2,
      height: 200,
      linecap: "butt"
    }).build();
  }

  drawGraph(optGroupNumStr: string, benefitID?: string) {
    this.totals = [];
    this.dataArray = [];
    this.benefitPlanID = this.benefitPlanID == undefined ? "0" : benefitID == "0" ? "0" : this.benefitPlanID;
    this.accountService.GetPlanTiers(this.groupName, this.status, this.benefitPlanID, optGroupNumStr).subscribe((data: PlanTiersResponse) => {
      this.planTiers = data.data.tiers;
      if (optGroupNumStr == "-1") {
        this.optgroups = data.data.optGroups;
        this.optGroupNum = "-1";

        this.optionGroupsVisible = data.data.optGroups == null ? false : data.data.optGroups.length == 0 ? false : true;
      }
      this.totals.push({
        "eeCost": data.data.tiers.length ? data.data.tiers.reduce((tot, tier) => tot += tier.eeCost, 0) : 0,
        "erCost": data.data.tiers.length ? data.data.tiers.reduce((tot, tier) => tot += tier.erCost, 0) : 0,
        "eeCovered": data.data.tiers.length ? data.data.tiers.reduce((tot, tier) => tot += tier.eeCovered, 0) : 0,
        "spouseCovered": data.data.tiers.length ? data.data.tiers.reduce((tot, tier) => tot += tier.spouseCovered, 0) : 0,
        "childCovered": data.data.tiers.length ? data.data.tiers.reduce((tot, tier) => tot += tier.childCovered, 0) : 0
      });

      if (data.data.tiers.length > 0) {
        data.data.tiers.forEach(element => {
          var t = element.tierName == null ? "Total" : element.tierName;
          this.dataArray.push({ "title": t, "value": element.eeCovered })
        });
        this.multiCircleVisible = true;
        this.createMultiCircle();
        this.eeCovered = this.totals[0].eeCovered.toString();
        this.spouseCovered = this.totals[0].spouseCovered.toString();
        this.childCovered = this.totals[0].childCovered.toString();
      }
      else {
        this.multiCircleVisible = false;
        this.eeCovered = "0";
        this.spouseCovered = "0";
        this.childCovered = "0";
      }

    });

  }

  optGroupChanged() {
    this.dataArray = [];
    this.drawGraph(this.optGroupNum);
  }

  planChanged() {
    this.dataArray = [];
    if (this.benefitPlanID != "0") {
      //this.optionGroupsVisible = true;
      this.drawGraph("-1");

    }
    else {
      this.optionGroupsVisible = false;
      this.drawGraph("-1");
    }


  }

}
