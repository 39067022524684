import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})

export class UserFingerprintService {

  constructor(private cookieService: CookieService, private router: Router) { }

  private getApiDomainFromApiPath(apiPath: string) {
    let apiDomain = `.${document.location.hostname}`;
    if (apiPath) {
      let apiHost = new URL(apiPath).hostname;
      let idx = apiHost.indexOf('.');
      if (idx > -1) {
        apiDomain = apiHost.substring(idx);
      }
    }
    return apiDomain;
  }

  setCookie(ufp: string, apiPath: string) {
    let apiDomain = this.getApiDomainFromApiPath(apiPath);
    this.cookieService.set('api-userfingerprint', ufp, .1, undefined, apiDomain, true, 'Lax');
  }

  deleteCookie(apiPath: string, logoutURL: string) {
    let apiDomain = this.getApiDomainFromApiPath(apiPath);
    this.cookieService.delete('api-userfingerprint', undefined, apiDomain, true, 'Lax');
    if (logoutURL)
      this.router.navigate(["/"]).then(result => { window.location.href = logoutURL; });
  }
}
