import { Component, Input, OnInit } from '@angular/core';
import { ThoughtLeadershipResponse } from '../../../../core/models/thoughtLeadershipResponse';

@Component({
  selector: 'app-thought-leadership',
  templateUrl: './thought-leadership.component.html',
  styleUrls: ['./thought-leadership.component.scss']
})
export class ThoughtLeadershipComponent implements OnInit {

  @Input() thoughtLeadershipItems: ThoughtLeadershipResponse;

  constructor() { }

  ngOnInit(): void { }
}
