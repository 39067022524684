<section class="component-heading">
  <h1>{{ 'Your Alight Contacts' }}</h1>
</section>
<section class="component-content">
  <ng-container *ngIf="contactCount > 0 else noContacts">
    <div class="grid flex align-content-center">

      <div class="column col-12" *ngFor="let contact of config.data.contacts"> 
        <section class="component primary-component"> 
          <section class="component-heading"> 
            <h2>{{contact.firstName}} {{contact.lastName}}</h2> 
          </section> 
          <p-card class="contact">
            <div class="contact__right p-ml-4">
              <p class="contact__right__title"><i class="fas fa-user" aria-hidden="true" [title]="'contact.contactTitle'"></i> {{contact.contactTitle}}</p>
              <p class="contact__right__phone"><i class="fas fa-phone-alt" aria-hidden="true" [title]="'shared.phoneIconTitle'"></i> {{contact.contactPhone}} {{contact.PhoneExt}}</p>
              <p class="contact__right__email"><i class="far fa-envelope" aria-hidden="true" [title]="'shared.emailIconTitle'"></i> <a [href]="contact.contactEmail | mailToEmail" [attr.aria-label]="'shared.emailAction'">{{contact.contactEmail}}</a></p>
            </div>
          </p-card> 
        </section>
      </div>
</div>
</ng-container>
<ng-template #noContacts>
  <p-card>
    <div>{{ 'No Contacts Available'}}</div>
  </p-card>
</ng-template>
</section>