export enum Language {
  None            = 0,
  English         = 1 << 0,
  Spanish         = 1 << 1,
  French          = 1 << 2,
  Japanese        = 1 << 3,
  Korean          = 1 << 4,
  Mandarin        = 1 << 5,
  HaitianCreole   = 1 << 6
}
