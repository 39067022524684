import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MegaMenuItem, MenuItem } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NavigationStateType } from '../../core/enums/NavigationStateType';
import { NavigationResponse } from '../../core/models/navigation-response';
import { NavigationModel } from '../../core/models/navigation-model';
import { NavigationModalComponent } from '../modals/navigation-modal/navigation-modal.component';
import { NavigationService } from './navigation.service';
import { AccountService } from '../../core/services/account.service';
import { Member } from '../../core/models/login/member';
import { SitePaths } from '../../core/models/sitePaths';
import { SitePathsResponse } from '../../core/models/sitePathsResponse';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  standardMenu: boolean = false;
  menuItems: MenuItem[];
  items: MegaMenuItem[];
  ref: DynamicDialogRef;
  commands: object;
  currentItem: MenuItem;
  siteName: string;
  member: Member;
  sitePaths: SitePaths;
  enableHrAdmin: boolean;
  hrAdminRoute:string;

  constructor(private accountService: AccountService, private primengConfig: PrimeNGConfig, private navigationService: NavigationService, public dialogService: DialogService, private router: Router) {
      this.commands = {
        showModal: (event) => { this.showModal(); },
        logout: (event) => { this.logout(); },
        goToAdmin: (event, queryParam) => {this.goToAdmin(queryParam);}
      }
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.member = this.accountService.getCurrentMember();

    this.navigationService.GetSitePaths().subscribe((data: SitePathsResponse) => {
      this.sitePaths = data.data;
    });

    this.siteName = this.accountService.getCurrentMember().siteName;

    this.navigationService.GetTopNavigation().subscribe((data: NavigationResponse) => {
      if(this.standardMenu) {
        this.items = data.items.map(nm => this.navModelToMegaMenuItem(nm));
        this.findState(this.items);
      } else {
        this.menuItems = this.findAdmin(data.items);
      }
    });
  }

  showModal() {
    this.ref = this.dialogService.open(NavigationModalComponent, {
      data: this.currentItem,
      modal:true
    });
  }

  logout() {
    this.accountService.logout();
    this.router.navigateByUrl('/login');
  }

  findState(items) {
    items.map(item => {
      if (item.items && item.items.length) {
        this.setState(item.items);
      }
    });
  };

  setState(items: MenuItem[][]) {
    items.map(item => {
      for (var i = 0; i < item.length; i++) {
        let midLevel = item[i];
        if (midLevel.items && midLevel.items.length) {
          for (var j = 0; j < midLevel.items.length; j++) {
            let bottomLevel = midLevel.items[j];
            let queryParam = bottomLevel.queryParams;
            if (bottomLevel.state) {
              bottomLevel.state.map(state => {
                if (state.type == NavigationStateType.Command) {
                  bottomLevel.command = (event) => { this.commands[`${state.name}`](event, queryParam) };
                  this.currentItem = bottomLevel;
                }
              });
            }
          }
        }
      }
    });
  }

  navModelToMegaMenuItem(nm: NavigationModel) : MegaMenuItem {
    let output: MegaMenuItem = {};
    output.label = nm.label;
    output.routerLink = nm.routerLink;
    output.url = nm.url;
    output.queryParams = nm.queryParams;
    output.state = nm.state;
    if (nm.items.length) {
      output.items = this.navModelToMenuItemArray(nm.items);
    }
    return output;
  }

  navModelToMenuItemArray(mi: NavigationModel[]): MenuItem[][] {
    let output: MenuItem[][] = [];
    output.push(mi.map(m => this.navModelToMenuItem(m)));
    return output;
  }

  navModelToMenuItem(nm: NavigationModel): MenuItem {
    let output: MenuItem = {};
    output.label = nm.label;
    output.routerLink = nm.routerLink;
    output.url = nm.url;
    output.queryParams = nm.queryParams;
    output.state = nm.state;
    if (nm.items && nm.items.length) {
      output.items = nm.items.map(nmi => this.navModelToMenuItem(nmi));
    }
    return output;
  }

  goToAdmin(menuRoute:[k:string]) {
    this.hrAdminRoute = "" + menuRoute;
    this.enableHrAdmin = true;
  }

  findAdmin(items) {
    var stateArr = items.map(item => {
      if (item.items.length > 0) {
        this.findState(item.items);
      } else if (item.state) {
        item.state.map(state => {
          if (state.type == NavigationStateType.Command) {
            // state.data can contain different or no elements depending on the contents of state.name
            // if new / different elements are added to state.name corresponding changes must be added below
            // identical changes must be made to the same method in the left-nav component
            if (state.data) {
              let menuRoute = state.data.route;
              item.command = (event) => { this.commands[`${state.name}`](event, menuRoute) };
            } else {
              item.command = (event) => { this.commands[`${state.name}`](event) };
            }
          }
        })
      }
      return item;
    });
    return stateArr;
  }
}
