import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { NavigationService } from '../../../shared/navigation/navigation.service';
import { Member } from '../../../core/models/login/member';
import { AccountService } from '../../../core/services/account.service';
import { ToDoService } from './to-do.service';
import { SitePaths } from '../../../core/models/sitePaths';
import { SitePathsResponse } from '../../../core/models/sitePathsResponse';
import { LockedUsersModalComponent } from './modals/locked-users-modal/locked-users-modal.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToDoAlerts } from '../../../core/models/toDoAlerts';
import { OEPeriod, OEPeriodList } from '../../../core/models/OEPeriods';

@Component({
  selector: 'app-to-do',
  templateUrl: './to-do.component.html',
  styleUrls: ['./to-do.component.scss'],
  providers: [DatePipe]
})
export class ToDoComponent implements OnInit {
  @Input() toDoList: ToDoAlerts;
  @Input() oePeriodList: OEPeriodList;
  linkList: ToDoAlerts;
  linkAlertCount: number = 0;
  downloadList: ToDoAlerts;
  downloadAlertCount: number = 0;

  ref: DynamicDialogRef;
  member: Member;
  toDoCount: number;
  toDoType: string;
  displayType: number;
  displayTitle: string;
  displayLink: boolean;
  sitePaths: SitePaths;
  fileName:string;
  currentOePeriod: number;
  oeperiod: OEPeriod;
  displayEpaType: number;
  displayEpaTitle: string;
  displayepaCount: number;
  oePeriodDisplay: any;
  employeeRoute: string;
  enableHrAdmin: boolean;
  hrAdminRoute:string;
  linkOepList: OEPeriod;
  downloadOepList: OEPeriod;

  constructor(private accountService: AccountService,
             private toDoService: ToDoService,
             private navigationService: NavigationService,
             private datePipe: DatePipe,
             private dialogService: DialogService) {
    this.member = this.accountService.getCurrentMember();
    this.navigationService.GetSitePaths().subscribe((data: SitePathsResponse) => {
      this.sitePaths = data.data;
    });
  }

  ngOnInit(): void {
    this.toDoCount = 0;
    this.displayType = 1;
    this.displayLink = false;
    this.displayTitle = "View All";

    if (this.toDoList) {
      this.linkList = new ToDoAlerts();
      this.downloadList = new ToDoAlerts();
      this.toDoType = "To-Dos";
      this.getAlerts();
    }

    this.displayEpaTitle = "View All";
    this.displayEpaType = 1;
    this.displayepaCount = 0;
    this.oeperiod = new OEPeriod;
    if (this.oePeriodList && this.oePeriodList.oePeriods.length > 0) {
      this.toDoType = "Annual Enrollment To-Dos";
      this.currentOePeriod =  this.oePeriodList.oePeriods[0].enrollPeriodID;
      this.getEPAlerts(this.currentOePeriod);
    }
    else {
      this.oePeriodList = null;
    }
  }

  getAlerts() {
    this.toDoList.Alerts.forEach(alert => {
      alert.alertType = 0;
      alert.displayType = 0;

      if (alert.count > 0 && alert.downloadName === "" && alert.modalID === "") {
        alert.alertType = 1;

        this.linkList.Alerts.push(alert);
        this.linkAlertCount++

        if (this.linkAlertCount > 5 || this.downloadAlertCount > 5) {
          alert.displayType = 1;
          this.displayLink = true;
        }
      }

      if (alert.count > 0 && alert.downloadName !== "" && alert.modalID === "") {
        alert.alertType = 2;

        this.downloadList.Alerts.push(alert);
        this.downloadAlertCount++;

        if (this.linkAlertCount > 5 || this.downloadAlertCount > 5) {
          alert.displayType = 1;
          this.displayLink = true;
        }
      }

      if (alert.count > 0 && alert.downloadName === "" && alert.modalID !== "" ) {
        alert.alertType = 3;

        this.linkList.Alerts.push(alert);
        this.linkAlertCount++;

        if (this.linkAlertCount > 5 || this.downloadAlertCount > 5) {
          alert.displayType = 1;
          this.displayLink = true;
        }
      }

    });

    this.toDoCount = this.linkAlertCount + this.downloadAlertCount;
  }

  setViewAll() {
    if (this.displayType == 1) {
      this.displayType = 2;
      this.displayTitle = "View Less";
    } else {
      this.displayType = 1;
      this.displayTitle = "View All";
    }
  }

  setViewEpaAll() {
    if (this.displayEpaType == 2) {
      this.displayEpaType = 3;
      this.displayEpaTitle = "View Less";
    } else {
      this.displayEpaType = 2;
      this.displayEpaTitle = "View All";
    }
  }

  oePeriodChanged(currentOeperiod) {
    this.currentOePeriod = currentOeperiod;
    this.getEPAlerts(this.currentOePeriod);
  }

  getEPAlerts(currentoeperiod) {
    if (this.oePeriodList && this.oePeriodList.oePeriods.length > 0) {
    this.oePeriodList.oePeriods.forEach(oep => {
      if (oep.enrollPeriodID ===  parseInt(currentoeperiod)) {
        this.oeperiod = oep;
      }
    });

    this.toDoCount = 0;
    this.linkOepList = new OEPeriod();
    this.downloadOepList = new OEPeriod();

    if (this.oeperiod != undefined && this.oeperiod != null) {

      this.oePeriodDisplay = [];

      this.oeperiod.enrollPeriodAlerts.forEach(epa => {

        epa.epaType = 0;
        epa.displayEpaType = 1;
        this.displayepaCount = 0;

        if (epa.count > 0 && epa.downloadName === null) {
          this.toDoCount++;
          this.displayepaCount++;
          epa.epaType = 1;
          epa.displayEpaType = 1;
          this.oePeriodDisplay.push(epa);

          this.linkOepList.enrollPeriodAlerts.push(epa);

          if (this.displayepaCount > 5) {
            epa.displayEpaType = 2;
            this.displayEpaType = 2;
          }
        }

        if (epa.count > 0 && epa.downloadName !== null) {
          this.toDoCount++;
          this.displayepaCount++;
          epa.epaType = 2;
          epa.displayEpaType = 1;
          this.oePeriodDisplay.push(epa);

          this.downloadOepList.enrollPeriodAlerts.push(epa);

          if (this.displayepaCount > 5) {
            epa.displayEpaType = 2;
            this.displayEpaType = 2;
          }
        }
      });
    }
  }
  else {
    this.oePeriodList = null;
  }
}

  getAlertDownload(downloadName: string) {
    let fileDate = new Date();
    let fileDateString = this.datePipe.transform(fileDate, 'yyyMMddss');
    this.fileName = downloadName + "_" + fileDateString + ".csv";

    this.toDoService.getAlertDownload(downloadName).subscribe( (response) => {
      const data: Blob = new Blob([response], {
        type: "text/csv; charset=utf-8"
      });
      saveAs(data, this.fileName);
    });
  }

  displayLockedEmp() {
    this.ref = this.dialogService.open(LockedUsersModalComponent, {
      data: this.toDoList,
      modal: true
    });
  }

  goToAdmin(hrAdminRoute:string) {
    this.hrAdminRoute = hrAdminRoute;
    this.enableHrAdmin = true;
  }
}
