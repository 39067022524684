import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Member } from '../../core/models/login/member';
import { Language } from '../../core/enums/language';
import { SiteFeatures } from '../../core/enums/siteFeatures';
import { UserFeatures } from '../../core/enums/userFeatures';
import { UserLinks } from '../../core/enums/userLinks';
import { User } from '../../core/models/user';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { jsonToEnum } from '../../core/enums/enumHelpers';
import { BYPASS_ERROR } from '../../core/interceptor/error.interceptor';
import jwtDecode, { JwtPayload } from "jwt-decode";
import { HRLinks } from '../../core/enums/hrLinks';
import { DashBoardLinks } from '../../core/enums/dashBoardLinks';
import { Router } from '@angular/router';
import { PlanTiersResponse } from '../../core/models/planTiers-response';
import { PlansResponse } from '../../core/models/plans-response';
import { map, retry } from 'rxjs/operators';
import { SiteDetailsRequest } from '../models/login/siteDetailsRequest';
import { SiteDetailsResponse } from '../models/login/siteDetailsResponse';
import { ConfigService } from './config.service';
import { UserFingerprintService } from '../../core/services/userfingerprint.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private currentMemberSource = new BehaviorSubject<Member>(this.getCurrentMember());
  currentMember$ = this.currentMemberSource.asObservable();
  member: Member;
  identityURL: string;

  constructor(private http: HttpClient, private router: Router, private configService: ConfigService, private userFingerprintService: UserFingerprintService) { }

  login(model: User, siteID: string) {

    siteID = siteID ? siteID : environment.siteId;

    const body = new HttpParams()
      .set('siteId', siteID)
      .set('client_id', environment.clientId)
      .set('client_secret', environment.clientSecret)
      .set('grant_type', 'password')
      .set('username', model.username)
      .set('password', model.password);

    if (model.accessToken) {
      this.processJwt({ access_token: model.accessToken });
      this.router.navigateByUrl('/home');
    }
    else {
      return this.http.post(this.configService.config.identityUrl + 'connect/token', body, {
        context: new HttpContext().set(BYPASS_ERROR, true)
      }).pipe(
        map((response: any) => {
          if (response.access_token) {
            this.processJwt(response);
          }
        }));
    }
  }

  logout() {
    let member = this.getCurrentMember();
    let ufpCookieAuthDisabled = this.isUFPCookieAuthDisabled();
    this.clearUserSession();
    if (member && !ufpCookieAuthDisabled) {
      let data = this.getSiteDetails().subscribe((data: SiteDetailsResponse) => {
        let siteDetails = data.SiteDetails;
        let logoutURL = (!document.location.href.includes("localhost:4200") ? siteDetails.AdminPath : environment.adminPath) + 'account/erportalproxylogout';
        this.userFingerprintService.deleteCookie(member.apiPath, logoutURL);
      });
    }
  }

  clearUserSession() {
    sessionStorage.removeItem('member');
    this.currentMemberSource.next(null);
    sessionStorage.clear();
  }

  getCurrentMember(): Member {
    return JSON.parse(sessionStorage.getItem("member") || '{}');
  }

  GetPlans(selectedGroup: string, selectedStatus: string): Observable<PlansResponse> {
    this.member = this.getCurrentMember();
    const headers = { 'Authorization': `Bearer ${this.member.token}` };
    let params = new HttpParams().set('groupName', selectedGroup).set('status', selectedStatus);

    return this.http.get<PlansResponse>(this.member.apiPath + 'Home/Dashboard/GetPlans', { 'headers': headers, 'params': params });
  }

  GetPlanTiers(groupName: string, status: string, benefitPlanID: string, optGroupNum: string): Observable<PlanTiersResponse> {
    this.member = this.getCurrentMember();
    const headers = { 'Authorization': `Bearer ${this.member.token}` };
    let params = new HttpParams().set('groupName', groupName).set('status', status).set('benefitPlanID', Number(benefitPlanID)).set('optGroupNum', Number(optGroupNum));

    return this.http.get<PlanTiersResponse>(this.member.apiPath + 'Home/Dashboard/GetPlanTiers', { 'headers': headers, 'params': params });
  }

  checkIfPwdExpired(): Observable<boolean> {
    this.member = this.getCurrentMember();    
    let params = new HttpParams().set('userId', this.member.userID);
    const requestUrl: string = `${this.member.apiPath}Home/Dashboard/IsPasswordExpired`;
    return this.http.get<boolean>(requestUrl, {'params': params});
  }

  setCurrentMember(member: Member) {
    sessionStorage.setItem('member', JSON.stringify(member));
  }

  getSiteDetails() {
    let siteDetails = new SiteDetailsRequest;
    let siteDetailsPath = this.configService.config.identityUrl;
    let ERPortalPath = window.location.origin;

    if (ERPortalPath.includes('localhost:4200')) {
      ERPortalPath = environment.siteDetails;
    }

    siteDetails.ERPortalPath = ERPortalPath;
    const headers = { 'content-type': 'application/json' };
    return this.http.post<SiteDetailsResponse>(`${siteDetailsPath}Auth/SiteDetails`, JSON.stringify(siteDetails), {'headers': headers}).pipe(retry(1));

  }

  public processJwt(response: any) {
    const token = this.getDecodedAccessToken(response.access_token);
    if (token?.SiteID && token?.FullName) {
      this.member = {
        token: response.access_token,
        refreshToken: response.refresh_token,
        //Uncomment this for using a local API and change the environment file apiPath to point to the local API
        //apiPath: environment.apiUrl,
        apiPath: token.ApiPath,
        memberID: token.MemberID,
        normalizedClientID: token.NormalizedClientId,
        globalID: token.GlobalID == undefined ? "" : token.GlobalID,
        siteID: token.SiteID,
        siteType: token.SiteType,
        newHireDaysLeft: token.NewHireDaysLeft,
        fullName: token.FullName,
        isAuthenticated: true,
        contentLanguage: jsonToEnum(Language, token.ContentLanguage),
        siteFeatures: jsonToEnum(SiteFeatures, token.SiteFeatures),
        userFeatures: jsonToEnum(UserFeatures, token.UserFeatures),
        userLinks: jsonToEnum(UserLinks, token.UserLinks),
        hrLinks: jsonToEnum(HRLinks, token.HRLinks),
        dashBoardLinks: jsonToEnum(DashBoardLinks, token.DashBoardLinks),
        authenticationStatus: token["sb:AuthenticationStatus"],
        siteName: token.SiteName,
        isImpersonation: token.IsImpersonation == "True",
        isInboundSso: token.IsInboundSso == "True",
        isAdmin: token.IsAdmin == "True",
        isHRUser: token.IsHRUser == "True",
        isMaster: token.IsMaster == "True",
        hasAlegeusClientId: token.HasAlegeusClientId == "True",
        userID: token.UserID
      };

      this.setCurrentMember(this.member);
      sessionStorage.setItem('tokenData', JSON.stringify(token));

      let ufpCookieAuthDisabled = this.isUFPCookieAuthDisabled();
      if (!ufpCookieAuthDisabled && token.ufp && response.ufp) {
        this.userFingerprintService.setCookie(response.ufp, this.member.apiPath);
      }
    }
  }

  public getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode<JwtPayload>(token);
    }
    catch (e) {
      return null;
    }
  }

  public isUFPCookieAuthDisabled() {
    try {
      let isDisabled = this.configService.config.disableUFPCookieAuth ?? false;
      return isDisabled;
    }
    catch (e) {
      console.log('failed to get config: disableUFPCookieAuth');
      return false;
    }
  }
}
