import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Member } from '../../core/models/login/member';
import { AccountService } from '../../core/services/account.service'
import { environment } from '../../../environments/environment';
import { BYPASS_ERROR } from '../../core/interceptor/error.interceptor';
import { ConfigService } from '../../core/services/config.service';
@Injectable({
  providedIn: 'root'
})
export class SsoService {
  member: Member;
  baseApiUrl: string;
  logging: boolean = true;

  constructor(private accountService: AccountService, private http: HttpClient, private configService: ConfigService) { }

    sso(jwt: string) {

      const body = new HttpParams()
      .set('client_id', environment.clientId)
      .set('client_secret', environment.clientSecret)
      .set('grant_type', 'refresh_token')
      .set('refresh_token', jwt);

      return this.http.post(this.configService.config.identityUrl + 'connect/token', body, {
        context: new HttpContext().set(BYPASS_ERROR, true)
      }).pipe(
        map((response: any) => {
          if (response.access_token) {
            this.accountService.processJwt(response);
          }
        }));
    }
}
