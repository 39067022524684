import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { MegaMenuModule } from 'primeng/megamenu';
import { ReactiveFormsModule } from '@angular/forms';



import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { MenubarModule } from 'primeng/menubar';
import { AccordionModule } from 'primeng/accordion';
import { FormsModule } from '@angular/forms';

import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { PanelModule } from 'primeng/panel';

import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';

import { MenuModule } from 'primeng/menu';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { ListboxModule } from 'primeng/listbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';

import { DividerModule } from 'primeng/divider';

import { ProgressBarModule } from 'primeng/progressbar';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { InputMaskModule } from 'primeng/inputmask';
import { ChartModule } from 'primeng/chart';
import { CalendarModule } from 'primeng/calendar';

import { ConfirmDialogModule } from 'primeng/confirmdialog';

import {EditorModule} from 'primeng/editor';
import {StepsModule} from 'primeng/steps';
import {DynamicDialogModule} from 'primeng/dynamicdialog';

import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';

import {PaginatorModule} from 'primeng/paginator';
import {BadgeModule} from 'primeng/badge';

import {BlockUIModule} from 'primeng/blockui';
import { ChipModule } from 'primeng/chip';
import {BreadcrumbModule} from 'primeng/breadcrumb';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MessagesModule,
    MessageModule,
    ReactiveFormsModule,
    BlockUIModule,
    ChipModule,
    PaginatorModule,
    SliderModule,
    BadgeModule,
    MultiSelectModule,
    BreadcrumbModule,
    ContextMenuModule,
    DynamicDialogModule,
    ToastModule,
    StepsModule,
    FormsModule,
    MenubarModule,
    MegaMenuModule,
    AccordionModule,
    CalendarModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    RippleModule,
    PanelModule,
    CardModule,
    CarouselModule,
    MenuModule,
    AvatarModule,
    AvatarGroupModule,
    TooltipModule,
    TableModule,
    TabViewModule,
    DropdownModule,
    RadioButtonModule,
    CheckboxModule,
    ListboxModule,
    SelectButtonModule,
    InputNumberModule,
    DividerModule,
    ProgressBarModule,
    DialogModule,
    InputMaskModule,
    ChartModule,
    InputSwitchModule,
    ConfirmDialogModule,
    EditorModule
  ],
  exports: [
    MessagesModule,
    MessageModule,
    ReactiveFormsModule,
    BlockUIModule,
    ChipModule,
    PaginatorModule,
    SliderModule,
    BadgeModule,
    MultiSelectModule,
    BreadcrumbModule,
    ContextMenuModule,
    DynamicDialogModule,
    ToastModule,
    StepsModule,
    FormsModule,
    MenubarModule,
    MegaMenuModule,
    AccordionModule,
    CalendarModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    RippleModule,
    PanelModule,
    CardModule,
    CarouselModule,
    MenuModule,
    AvatarModule,
    AvatarGroupModule,
    TooltipModule,
    TableModule,
    TabViewModule,
    DropdownModule,
    RadioButtonModule,
    CheckboxModule,
    ListboxModule,
    SelectButtonModule,
    InputNumberModule,
    DividerModule,
    ProgressBarModule,
    DialogModule,
    InputMaskModule,
    ChartModule,
    InputSwitchModule,
    ConfirmDialogModule,
    EditorModule
  ]
})
export class ExternalModule {}
