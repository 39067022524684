import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import { SecureLayoutComponent } from '../../shared/secure-layout/secure-layout.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';

const SECURE_ROUTES: Routes = [
  { path: 'user-settings', component: UserSettingsComponent}
];

const routes: Routes = [{
  path: '',
  component: SecureLayoutComponent,
  runGuardsAndResolvers: 'always',
  canActivate: [AuthGuard],
  children: SECURE_ROUTES
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})


export class UserSettingsRoutingModule { }
