<section class="component primary-component">
  <div class="component-content">
    <div class="component-heading">
      <h2>Alight Thought Leadership</h2>
    </div>
    <div class="thought-leadership">
      <div *ngFor="let card of thoughtLeadershipItems?.data">
        <app-thought-leadership-card [thoughtLeadershipModel]="card"></app-thought-leadership-card>
      </div>
    </div>
  </div>
</section>