/*
Multiline Circle Graph
J Gibbens 2021

*/
export class MultiCircleGraph {

  constructor(data) {
    this.target = data.target;
    this.graph_title = data.graph_title || null;
    this.data_values = data.data_values;
    this.ammount_font_size = data.ammount_font_size || null;
    this.ammount_offset_top = data.ammount_offset_top || 0;
    this.title_ammount = data.title_ammount || 0;
    this.sub_message = data.sub_message || null;
    this.sub_message_width = data.sub_message_width || '60%';
    this.inner_title = data.inner_title || null;
    this.view_box = data.view_box || 30;
    this.stroke_width = data.stroke_width || 3;
    this.width = data.width || null;
    this.height = data.height || 150;
    this.title = data.title || null;
    this.linecap = data.linecap || 'butt'; //square || round || butt 
    this.hide_ammount = data.hide_ammount || false;
    this.use_percent = data.use_percent || false; // will we use percentages?
    this.cols = data.cols || " ";
    this.four_graph = data.four_graph || false;
    this.total_ammount = data.total_ammount;

    this.color_arr = data.color_arr || ['#8FE3DE', '#C3ABDA', '#A1C3FD', '#fff100', '#478CFF', '#FF9966', '#64D9D2', '#639EFE', '#BB6BD9', '#2DBE4D', '#FBB836', '#EE3F3F'];    
  }

  build() {
    this.data_values.sort(GetSortOrder("value")); // order everything so it has the correct z-index




    function shuffle(array) {
      var currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]
        ];
      }

      return array;
    }



    function GetSortOrder(prop) {
      return function(a, b) {
        if (a[prop] < b[prop]) {
          return 1;
        } else if (a[prop] > b[prop]) {
          return -1;
        }
        return 0;
      }
    }

    function msg(msg) {
      // console.log(msg)
    }

    function getPercentage(pPos, pEarned) {
      var _largestNumber = Math.max(pPos, pEarned),
        _smallestNumber = Math.min(pPos, pEarned),
        perc = "";
      if (isNaN(pPos) || isNaN(pEarned)) {
        perc = " ";
      } else {
        perc = ((_smallestNumber / _largestNumber) * 100).toFixed(3);
      }


      return perc;
    }

    ///add that comma
    function addComma(j) {
      var y = j.toString(),
        result = y.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      return result;
    }

    function getOffset(val) {
      var t = 100 - val,
        y = _currOffset + t;

      if (y > 100) {
        y = y - 100
      }

      return y;
    }

    function circ(val) {
      msg(val)

      return 100 - val;
    }
    var _app = document.getElementById(this.target),
      _totalAmm = document.getElementById(this.total_ammount),
      _percentage = 0,
      _ammountNumber = 0,
      _cx_cy = this.view_box / 2,
      _r = _cx_cy - 1,
      _tableView = false,
      _currCirc = 0,
      _data1 = [],
      _totalValue = 0,
      _currRot = -90,
      _json = this.data_values,
      _title = ' <div class="title">' + this.title + '</div> ',
      inner_title = '<div class="inner_title">' + this.inner_title + '</div> ',
      _ammount = "",
      _build_graph_go = "",
      sheet = window.document.styleSheets[0],
      _table = "",
      _end = '</svg>',
      _rotate = 0,
      _bg_circ = '',
      _graph_circ = "",
      _currOffset = 25,
      _graph = "";

    //    arr_shuff = shuffle(this.color_arr);

    if (this.width == null) {
      this.width = this.height;
    }

    //add height and width
    _graph += '<svg id="' + this.target + '_svg" class="circle-chart"  x="0px" y="0px" viewbox="0 0 50 50" width="200" height="200" xmlns="http://www.w3.org/2000/svg">';
    _bg_circ += '<circle class="circle-chart__background"  stroke="#f5f5f5" stroke-width="5" fill="none" cx="25" cy="25" r="20" />';

    for (var p in this.data_values) {
      _totalValue += this.data_values[p].value;
    }


    var _firstPos = 0;

    for (var t in this.data_values) {

      _graph_circ += '<circle ';
      _graph_circ += 'stroke-dasharray="';
      _graph_circ += getPercentage(_totalValue, this.data_values[t].value);
      _graph_circ += ', ';
      _graph_circ += circ(getPercentage(_totalValue, this.data_values[t].value));
      _graph_circ += '" '
      _graph_circ += 'stroke-dashoffset="';
      _graph_circ += _currOffset;
      if (t == 0) {
        _firstPos = _currOffset;
      }
      _graph_circ += '"';
      _graph_circ += 'style="stroke: ' + this.color_arr[t] + '; position:relative;  ';
      _graph_circ += '" class="circle-chart__circle" stroke="';
      _graph_circ += this.color_arr[t];
      _graph_circ += '" stroke-width="8"'
      _graph_circ += ' stroke-linecap="';
      _graph_circ += this.linecap;
      _graph_circ += '" fill="none" cx="25" cy="25" r="17" />';


      _currOffset = getOffset(getPercentage(_totalValue, this.data_values[t].value))

      _ammountNumber += this.data_values[t].value;

      _currCirc++;
    }

    if (this.linecap == "round") {
      _graph_circ += '<circle class="circle-chart__circle linecap__extra" stroke-dasharray=".1,100" stroke-dashoffset="';
      _graph_circ += _currOffset;
      _graph_circ += '" style="position:relative;stroke:';
      _graph_circ += this.color_arr[0];
      _graph_circ += '";  stroke-width="';
      _graph_circ += this.stroke_width;
      _graph_circ += '" stroke-linecap="';
      _graph_circ += this.linecap;
      _graph_circ += '"  fill="none" cx="';
      _graph_circ += _cx_cy;
      _graph_circ += '" cy="';
      _graph_circ += _cx_cy;
      _graph_circ += '" r="';
      _graph_circ += _r;
      _graph_circ += '" />';
    }

    if (_tableView) {
      _table += '<div id="' + this.target + '_table" class="">';

    } else {
      _table += '<div id="' + this.target + '_table">';

    }

    //_table += '<table class="">';
    //_table += '<tbody>';

    function _buildTableItems(color, title, data) {
      _table += '<div class="circle-graph-data-table">';
      _table += '<span class="circ_graph_dot" style="display:inline-block;width:.75rem;height:.75rem;border-radius:1rem;background:' + color + '"></span><span>' + title + '</span>';
      _table += '<span class="">' + data + '</span>';
      _table += '</div>';
    }

    for (var x in this.data_values) {
      _buildTableItems(this.color_arr[x], this.data_values[x].title, addComma(this.data_values[x].value));
      if (x = 2) {
        _tableView = true;
      }
    }

    //_table += '</tbody></table>';
    //_table += '</div>';

    _ammount = '<div>';

    /* if (this.ammount_font_size != null) {
      _ammount += 'font-size:' + this.ammount_font_size + '; '
    }
    _ammount += ' top:' + this.ammount_offset_top + 'px">'; */

    if (this.sub_message != null) {
      _ammount += '<div>' + this.sub_message + '</div>';
    }

    _ammount += '</div>'

    if (_tableView) {
      _build_graph_go += '<div id="' + this.target + '_svg_wrapper" class="svg_wrapper">';

    } else {
      _build_graph_go += '<div id="' + this.target + '_svg_wrapper" class="svg_wrapper col-12">';

    }

    _build_graph_go += _graph;
    _build_graph_go += _bg_circ;
    _build_graph_go += _graph_circ;
    _build_graph_go += _end;

    if (!this.hide_ammount) {
      _build_graph_go += _ammount;
    }

    if (this.title != null) {
      _build_graph_go += _title;
    }

    _build_graph_go += "</div>";

    _app.innerHTML = _build_graph_go;

    if (_tableView) {
      _app.innerHTML += _table;
    }

    if (_tableView) {
      // sheet.insertRule('#' + this.target + '_svg { width: 40%;height: 40% }', sheet.cssRules.length);
    }

    {
      if (_totalAmm != null)
        _totalAmm.innerHTML = "$" + addComma(_totalValue.toFixed(2));
    }
  }

}
