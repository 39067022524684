import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SsoRoutingModule } from './sso-routing.module';
import { SsoComponent } from './sso.component';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [

    SsoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SsoRoutingModule
  ]
})
export class SsoModule { }
