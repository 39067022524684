import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PasswordResponse } from '../../../../core/models/passwordResponse';
import { PasswordRequest } from '../../../../core/models/passwordRequest';
import { ProfileService } from '../profile.service';
import { Member } from '../../../../core/models/login/member';
import { AccountService } from '../../../../core/services/account.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent {
  passwordRequest: PasswordRequest = new PasswordRequest();
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  validationErrors: string[] = [];
  messageArray: string[];
  responseMsg: string;
  member: Member;

  constructor(private profileService: ProfileService,
              private accountService: AccountService,
              private router: Router) {
    this.member = this.accountService.getCurrentMember();
  }

  savePassword() {

    this.passwordRequest.OldPassword = this.oldPassword;
    this.passwordRequest.NewPassword = this.newPassword;
    this.passwordRequest.ConfirmPassword = this.confirmPassword;
    this.passwordRequest.UserId = this.member.userID;
    this.validationErrors = this.profileService.ValidatePassword(this.passwordRequest)

    if (this.validationErrors?.length > 0) {
      return;
    }

    this.profileService.CheckMyPassword(this.oldPassword).subscribe((checkPswd: PasswordResponse) => {
      if (checkPswd.data) {
        console.log('CheckPswd: ', checkPswd);
      } else {
        this.responseMsg = checkPswd.message;
        this.messageArray = this.responseMsg.split(',');
        this.messageArray.forEach((element, index) => {
          if (element.length === 0 || element.toString().includes('Password change was unsuccessful')) this.messageArray.splice(index, 1);
        });
        this.validationErrors = this.messageArray;
        return;
      }
    });

    this.profileService.ChangeMyPassword(this.passwordRequest).subscribe((changePswd: PasswordResponse) => {
      if (changePswd.data) {
        console.log('ChangePswd: ', changePswd);
        this.member.authenticationStatus = "Valid";
        this.accountService.setCurrentMember(this.member);
        this.router.navigate(['/home']);
      } else {
        this.responseMsg = changePswd.message;
        this.messageArray = this.responseMsg.split(',');
        this.messageArray.forEach((element, index) => {
          if (element.length === 0 || element.toString().includes('Password change was unsuccessful')) this.messageArray.splice(index, 1);
        });
        this.validationErrors = this.messageArray;
      }
    });




  }
}
