
export class ToDoAlerts {
  Alerts: Array<Alert> = [];
}

export class Alert {
  header: string;
  count: number;
  cssClass: string;
  description: string;
  linkURL: string;
  downloadName: string;
  modalID: string;
  id: number;
  announcementsLastUpdate: string;
  releaseNotesLastUpdate: string;
  referenceMaterialsLastUpdate: string;
  viewable: boolean;
  alertType: number;
  displayType: number;
}
