<div class="locked-out-users-modal">
  <h3>Locked-out Employees</h3>
  <div class="dashboard-todos2" *ngIf="!displayPassword">
    <div class="dashboard-todos__item">
      <div class="dashboard-todos__title">Name</div>
      <div class="dashboard-todos__title inline-center">Change Password</div>
      <div class="dashboard-todos__title inline-center">Employee Record</div>
    </div>
    <div class="dashboard-todos">
      <div class="dashboard-todos__item" *ngFor="let lockUser of lockedUsers.data">
        <div class="dashboard-todos__title">{{lockUser.firstName}} {{lockUser.lastName}}</div>
        <a class="dashboard-todos__title inline-center" (click)="changePassword(lockUser)"><i class="far fa-key"></i></a>
        <a class="dashboard-todos__title inline-center" (click)="getEmployee(lockUser)"><i class="far fa-address-card"></i></a>
      </div>
    </div>
    <div class="align-right button-container">
      <p-button pRipple type="button" label="Close" (click)="closeModal()" styleClass="p-button-plain"></p-button>
    </div>
  </div>
  <app-user-password [lockUser]="lockUser" (responseHeader)="closePasword($event)" *ngIf="displayPassword"></app-user-password>
</div>
<app-goto-admin [hrAdminRoute]="hrAdminRoute" *ngIf="enableHrAdmin"></app-goto-admin>


