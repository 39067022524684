import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountService } from '../../core/services/account.service';
import { ContactsResponse } from '../models/contacts-response';
import { Member } from '../models/login/member';


@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  private member: Member;

  constructor(private http: HttpClient, private accountService: AccountService) {
    this.member = this.accountService.getCurrentMember();
  }

  GetContacts(): Observable<ContactsResponse> {
    return this.http.get<ContactsResponse>(this.member.apiPath + 'Home/Dashboard/GetContacts');
  }
}
