<div class="content content-two-column-aside-left-fixed">
  <div class="column column-primary">
    <section class="component pagetitle-component">
      <h1>User Settings</h1>
    </section>
    <section class="component primary-component">
      <p-tabView>
        <p-tabPanel header="My Information">
          Content 1
        </p-tabPanel>
        <p-tabPanel header="Security">
          <p-card>
            <form action="">
              <fieldset>
                <legend>Title</legend>
              </fieldset>
            </form>
          </p-card>
        </p-tabPanel>
        <p-tabPanel header="Dependents &amp; Beneficiaries">
          Content 3
        </p-tabPanel>
      </p-tabView>
    </section>
  </div>
  <!-- SIDEBAR NAVIGATION -->
  <aside class="column column-aside">
    <!-- ***** COLUMN -->
    <!-- Stackable Component Containers-->
    <section class="component primary-component sticky">
      <app-left-nav></app-left-nav>
    </section>
  </aside>
</div>
