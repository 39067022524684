import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry, map } from 'rxjs/operators';
import { ChangeEmployeePasswordResponse } from 'src/app/core/models/changeEmployeePasswordResponse';
import { ChangeEmployeePasswordModel } from '../../../core/models/changeEmployeePasswordModel';
import { LockedUsersResponse } from '../../../core/models/lockedUsersResponse';
import { Member } from '../../../core/models/login/member';
import { ToDoResponse } from '../../../core/models/toDosResponse';
import { AccountService } from '../../../core/services/account.service';

@Injectable({
  providedIn: 'root'
})
export class ToDoService {
  member: Member;

  constructor(private accountService: AccountService, private http: HttpClient) {
    this.member = this.accountService.getCurrentMember();
   }

  getAlerts(): Observable<ToDoResponse> {
    return this.http.get<ToDoResponse>(this.member.apiPath + 'home/dashboard/getalerts').pipe(retry(1));
   }

   getAlertDownload(downloadName:string): Observable<any> {
     return this.http.get(this.member.apiPath + 'home/dashboard/getalertdownload', { params: new HttpParams().set('downloadName', downloadName), responseType: 'blob' })
       .pipe(retry(1));
   }

   getAlertModalData(modalID:string) {
     return this.http.get<LockedUsersResponse>(this.member.apiPath + 'home/dashboard/getalertmodaldata', { params: new HttpParams().set('modalID', modalID) })
       .pipe(retry(1));
   }

   putChangeEmployeePassword(changeEmployeePassword: ChangeEmployeePasswordModel): Observable<ChangeEmployeePasswordResponse> {
    return this.http.put(this.member.apiPath + 'home/dashboard/changepassword', JSON.stringify(changeEmployeePassword))
    .pipe(retry(1),
      map((response: ChangeEmployeePasswordResponse) => {
        return response;
      }));
   }
}
