import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigService } from './core/services/config.service';
import { AppRoutingModule } from '../app/app-routing.module';
import { AppComponent } from '../app/app.component';
import { SharedModule } from '../app/shared/shared.module';
import { LoginModule } from '../app/features/login/login.module';
import { SsoModule } from '../app/features/sso/sso.module';
import { HomeModule } from '../app/features/home/home.module';
import { ComponentsModule } from '../app/features/components/components.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserSettingsModule } from '../app/features/user-settings/user-settings.module';
import { DialogService } from 'primeng/dynamicdialog';
import { JwtInterceptor } from '../app/core/interceptor/jwt.interceptor';
import { ErrorInterceptor } from "../app/core/interceptor/error.interceptor";
import { MessageService } from 'primeng/api';
import { LoadingInterceptor } from './core/interceptor/loading.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FileSaverModule } from 'ngx-filesaver';

export const configFactory = (configService: ConfigService) => {
  return () => configService.loadConfig();
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FileSaverModule,
    NgxSpinnerModule,
    SharedModule,
    LoginModule,
    SsoModule,
    HomeModule,
    ComponentsModule,
    UserSettingsModule,
    AppRoutingModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: configFactory, deps: [ConfigService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    MessageService,
    DialogService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
