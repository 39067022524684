import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountService } from '../services/account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private accountService: AccountService, private router: Router) { }

  canActivate(route:ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return of(this.accountService.getCurrentMember()).pipe(
      map(member => {
        if (member && member.isAuthenticated) {
          return true;
        }

        sessionStorage.setItem('redirectUrl', window.location.pathname + window.location.search);
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
      }));
  }

  canLoad(): Observable<boolean> {
    return of(this.accountService.getCurrentMember()).pipe(
      map(member => {
        if (member && member.isAuthenticated) {
          return true;
        }

        sessionStorage.setItem('redirectUrl', window.location.pathname + window.location.search);
        this.router.navigate(['/login']);
        return false;
      }));
  }
}
