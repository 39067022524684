import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalModule } from '../core/modules/external.module';
import { NavigationComponent } from './navigation/navigation.component';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { SecureLayoutComponent } from './secure-layout/secure-layout.component';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { LeftNavComponent } from './left-nav/left-nav.component';
import { FooterComponent } from './footer/footer.component';
import { NavigationModalComponent } from './modals/navigation-modal/navigation-modal.component';
import { AlightContactsComponent } from './modals/alight-contacts/alight-contacts.component';
import { MailToEmailPipe } from './mail-to-email.pipe';
import { ProfileComponent } from './left-nav/profile/profile.component';
import { SharedRoutingModule } from './shared-routing.module';
import { TwoFactorComponent } from './left-nav/profile/two-factor/two-factor.component';
import { ChangePasswordComponent } from './left-nav/profile/change-password/change-password.component';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
import { GotoAdminComponent } from './navigation/goto-admin/goto-admin.component';
import { IdleUserModalComponent } from './modals/idle-user-modal/idle-user-modal.component';
import { FormatTimePipe } from './pipes/format-time.pipe';


@NgModule({
  declarations: [
    NavigationComponent,
    NotFoundComponent,
    LeftNavComponent,
    PublicLayoutComponent,
    SecureLayoutComponent,
    FooterComponent,
    NavigationModalComponent,
    AlightContactsComponent,
    MailToEmailPipe,
    ProfileComponent,
    TwoFactorComponent,
    ChangePasswordComponent,
    ConfirmationModalComponent,
    GotoAdminComponent,
    IdleUserModalComponent,
    FormatTimePipe,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    ExternalModule,
    SharedRoutingModule
  ],
  exports: [
    CommonModule,
    ExternalModule,
    NavigationComponent,
    LeftNavComponent,
    NotFoundComponent,
    PublicLayoutComponent,
    SecureLayoutComponent,
    AlightContactsComponent,
    ChangePasswordComponent,
    GotoAdminComponent,
    MailToEmailPipe,
    FormatTimePipe
  ]
})
export class SharedModule {}
