// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientId: 'ERPortal_Client',
  clientSecret: '7d87e1a46e61470487f4977758961c224fe842569aef415b86803fbac04d937f',
  appInsightsInstrumentationKey: '9ef1750c-9796-47a5-a323-0e7a163526b8',
  ivaJsSrcUrl: "https://aonh-test2.nextit.com/Alme/nextit-script-manager.js",
  enableVerifyIntegration: false,
  //apiUrl: 'https://sb4st2erportal.smartben.localhost:44397/api/',
  //apiUrl: 'https://localhost:44397/api/',
  apiUrl: 'https://acdcerportalapi.azurewebsites.net/api/',
  identityUrl: 'https://sb4sso.wl.dev.alight.com/',
  //siteDetails: 'https://sb4st2erportal.wl.dev.alight.com',
  siteDetails: 'https://sb4st3erportal.wl.dev.alight.com',
  //siteId: '1714',  //site ST1
  //siteId: '1508', //site ST2
  siteId: '2409', //site ST3
  //siteId: '2092', //site ST4

  //identityUrl: 'https://localhost:5001/
  thoughtUrl: 'https://www.alight.com',
  idleTimeout: 600,
  idleModalTimeout: 300,
  adminPath: 'https://sb4st2admin.smartben.localhost:6002/'
};
