<ngx-spinner template="
<div class='wl-spinner'>
    <div class='wl-spinner__particle'></div>
    <div class='wl-spinner__particle'></div>
    <div class='wl-spinner__particle'></div>
    <div class='wl-spinner__particle'></div>
    <div class='wl-spinner-logo'></div>
</div>
">    
</ngx-spinner>
<p-toast position="bottom-center" key="appToast" [preventDuplicates]="true"></p-toast>
<router-outlet></router-outlet>
