export function formValueToBit(enumeration: object, selection: boolean[]) {
  const bits = enumToBitValues(enumeration);
  return selection
      .reduce((total, selected, i) => total + (selected ? bits[i] : 0), 0);
}

export function bitToFormValue(enumeration: object, bit: number) {
  const bits = enumToBitValues(enumeration);
  return bits.map(b => (bit & b) === b);
}

export function enumToBitValues(enumeration: object) {
  return Object.keys(enumeration)
      .map(Number)
      .filter(Boolean);
}

export function jsonToEnum(enumeration: any, enumList: string)
{
  if (enumList && enumList.length > 0) {
      let enumSum = 0;

      let enumArray = enumList.split(',').map(item => item.trim());
      enumArray.forEach(element => {
          let enumNumber = enumeration[element];

          if (enumNumber) {
              enumSum += enumNumber;
          }
      });

      return enumeration = enumSum;
  }

  return enumeration;
}
