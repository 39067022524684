import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-navigation-modal',
  templateUrl: './navigation-modal.component.html',
  styleUrls: ['./navigation-modal.component.scss']
})
export class NavigationModalComponent implements OnInit {
  currentItem: MenuItem;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
      this.currentItem = config.data as MenuItem;
    }

  ngOnInit(): void {}

  closeModal() {
    this.ref.close();
  }
}
