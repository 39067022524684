import { Component, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { LockedUsersResponse } from '../../../../../core/models/lockedUsersResponse';
import { ToDoService } from '../../to-do.service';
import { Router } from '@angular/router';
import { LockedUsersModel } from '../../../../../../../src/app/core/models/LockedUsersModel';
import { NavigationService } from '../../../../../../../src/app/shared/navigation/navigation.service';
import { AccountService } from '../../../../../../../src/app/core/services/account.service';
import { Member } from '../../../../../../../src/app/core/models/login/member';
import { SitePathsResponse } from '../../../../../core/models/sitePathsResponse';
import { SitePaths } from '../../../../../core/models/sitePaths';
import { ToDoAlerts } from '../../../../../core/models/toDoAlerts';

@Component({
  selector: 'app-locked-users-modal',
  templateUrl: './locked-users-modal.component.html',
  styleUrls: ['./locked-users-modal.component.scss']
})
export class LockedUsersModalComponent implements OnInit {
  @ViewChild('employeeForm') employeeFormElement;
  lockedUsers: LockedUsersResponse;
  lockUser: LockedUsersModel;
  hrAdminRoute: string;
  enableHrAdmin: boolean = false;
  member: Member;
  sitePaths: SitePaths;
  userID: number;
  selected_userID: number;
  modalID: string;
  toDoAlerts: ToDoAlerts;
  displayPassword: boolean;
  responseHeader: string;

  constructor(private ref: DynamicDialogRef,
              private config: DynamicDialogConfig,
              private toDoService: ToDoService,
              private accountService: AccountService,
              private navigationService: NavigationService,
              private router: Router ) {
    this.toDoAlerts = config.data;
    this.member = this.accountService.getCurrentMember();
    this.navigationService.GetSitePaths().subscribe((data: SitePathsResponse) => {
      this.sitePaths = data.data;
    });
  }

  ngOnInit(): void {
    this.displayPassword = false;
    this.responseHeader = undefined;

    this.toDoAlerts.Alerts.forEach(alert => {
      if (alert.header ==  "Locked-out Employees") {
        this.modalID = alert.modalID;
      }
    });

    this.toDoService.getAlertModalData(this.modalID).subscribe((data: LockedUsersResponse) => {
      this.lockedUsers = data;
    });
  }

  changePassword(lockUser: LockedUsersModel) {
    this.displayPassword = this.displayPassword == false;
    this.lockUser = lockUser
  }

  getEmployee(lockUser: LockedUsersModel) {
    this.hrAdminRoute = "EmployeeManagement/EmployeeRecords/" + lockUser.memberID + "/account?tokenID=" + lockUser.tokenID;
    this.enableHrAdmin = true;
  }

  closePasword(msg: string) {
    this.responseHeader = msg;
    this.changePassword(this.lockUser);
    this.toDoService.getAlertModalData(this.modalID).subscribe((data: LockedUsersResponse) => {
      this.lockedUsers = data;
    });
    this.lockUser = undefined;
  }

  closeModal() {
    this.toDoService.getAlertModalData(this.modalID).subscribe((data: LockedUsersResponse) => {
      this.lockedUsers = data;
    });
    this.ref.close();
    window.location.reload();
  }
}
