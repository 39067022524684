import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContextToken } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';
import { catchError } from 'rxjs/operators';

export const BYPASS_ERROR = new HttpContextToken(() => false);

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private messageService: MessageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error) {
          switch (error.status) {
            case 400:
              if (error.error.errors) {
                const modelStateErrors = [];
                for (const key in error.error.errors) {
                  if (error.error.errors[key]) {
                    modelStateErrors.push(error.error.errors[key]);
                  }
                }
                throw modelStateErrors.flat();
              } else {
                this.messageService.add({key:'appToast', severity:'error', summary:error.status, detail:error.statusText});
              }
              break;

            case 401:
            case 404:
              this.messageService.add({key:'appToast', severity:'error', summary:error.status, detail:error.message});
              break;

            default:
              this.messageService.add({key:'appToast', severity:'error', summary:'Error', detail:'Something unexpected went wrong'});
              break;
          }
        }
        return throwError(error);
      })
    );
  }
}
