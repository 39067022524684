export enum SiteFeatures {
  None                        = 0,

  UseFacility                 = 1 << 0,
  HasAvatarMultiLanguages     = 1 << 1,
  HasContentMultiLanguages    = 1 << 2,
  HideCompleteNewHire         = 1 << 3,
  HideCompleteAnnual          = 1 << 4,
  HideCompleteACA             = 1 << 5,
  ShowNewHireEnrollment       = 1 << 6,
  ShowLifeEventEnrollment     = 1 << 7,

  ShowAnnualEnrollment        = 1 << 8,
  UsePTOCredits               = 1 << 9,
  HideYoutube                 = 1 << 10,
  HasNOW                      = 1 << 11,
  ShowAvatar                  = 1 << 12,
  HasSentry                   = 1 << 13,
  UseGatekeeperSso            = 1 << 14,
  HasCompass                  = 1 << 15,

  HasAlegeus                  = 1 << 16,
  HasMultipleAvatar           = 1 << 17,
  UdpEnabled                  = 1 << 18,
  IvaEnabled                  = 1 << 19,
}
