import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuItem, MessageService } from 'primeng/api';
import { trigger, state, style, transition, animate } from '@angular/animations';


interface City {
  name: string,
    code: any
}


@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.scss'],
  providers: [MessageService],
  animations: [
    trigger('errorState', [
      state('hidden', style({
        opacity: 0
      })),
      state('visible', style({
        opacity: 1
      })),
      transition('visible => hidden', animate('400ms ease-in')),
      transition('hidden => visible', animate('400ms ease-out'))
    ])
  ]

})
export class ComponentsComponent implements OnInit {

  cities: City[];
  selectedCity: City;
  form: FormGroup;
  submitted = false;
  value: Date;
  val: number = 0;
  items: MenuItem[];

  display: boolean = false;

  inputSwitch: boolean;
  stateOptions: any[];
  value1: string = "off";

  blockedPanel: boolean;

  public errMsg: string = "This is a required field";

  // required settings
  public inputText1Req: boolean = true;
  public cal1Req: boolean = false;
  public ddReq: boolean = true;

  public chkbx1Req: boolean = false;
  public chkbx2Req: boolean = true;

  constructor(
    private messageService: MessageService,
    private formBuilder: FormBuilder
  ) {

    this.stateOptions = [
      { label: "Off", value: "off" },
      { label: "On", value: "on" }
    ];

    this.cities = [
      { name: 'New York', code: 'NY' },
      { name: 'Rome', code: 'RM' },
      { name: 'London', code: 'LDN' },
      { name: 'Istanbul', code: 'IST' },
      { name: 'Paris', code: 'PRS' }
    ];
  }


  ngOnInit(): void {
    this.initForm();
    this.theStepper();

  }




  showViaService() {
    this.messageService.add({ severity: 'error', summary: 'Service Message', detail: 'Via MessageService' });
  }



  theStepper() {
    this.items = [
      { label: 'Step 1' },
      { label: 'Step 2' },
      { label: 'Step 3' }
    ];
  }



  initForm() {
    this.form = this.formBuilder.group({
      inputtext: ['', [Validators.required]],
      citiesDropDown: ['', [Validators.required]],



      chkbx1: ['', []],
      chkbx2: ['', [Validators.required]],


      cal1: ['', []]
    })
  }

  get getform() {
    return this.form.controls;
  }
  onSubmit() {
    this.showViaService();
    this.submitted = true;

    if (!this.form.valid) {
      return;
    }
    alert('SUCCESS' + JSON.stringify(this.form.value));
  }
  onReset() {
    this.submitted = false;
    this.form.reset();
  }


}
