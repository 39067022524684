import { Component, Input, OnInit } from '@angular/core';
import { ThoughtLeadershipModel, ThoughtLeadershipTag } from '../../../core/models/thoughtLeadershipResponse';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-thought-leadership-card',
  templateUrl: './thought-leadership-card.component.html',
  styleUrls: ['./thought-leadership-card.component.scss']
})
export class ThoughtLeadershipCardComponent implements OnInit {
  @Input() thoughtLeadershipModel: ThoughtLeadershipModel = new ThoughtLeadershipModel();
  hasImage: boolean = false;
  hasTags: boolean = false;
  imageUrl: string;
  url: string;
  tags: ThoughtLeadershipTag[] = [];

  constructor() { }

  ngOnInit(): void {

    if (this.thoughtLeadershipModel.tileImageUrl) {
      this.imageUrl = environment.thoughtUrl + this.thoughtLeadershipModel.tileImageUrl.replace('~', '');
      this.hasImage = true;
    }

    this.url = environment.thoughtUrl + this.thoughtLeadershipModel.url;

    if (this.thoughtLeadershipModel.tags && this.thoughtLeadershipModel.tags.length > 0) {

      this.thoughtLeadershipModel.tags.forEach(tag => {
        this.tags.push({
          name: tag.name,
          navigationName: tag.navigationName,
          url: environment.thoughtUrl + tag.url,
          isOperation: tag.isOperation,
          tagGUID: tag.tagGUID
        });
      });

      this.hasTags = true;
    }
  }

  thoughtLeadership() {
    window.open(this.url);
  }
}
