import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LockedUsersModel } from '../../../../../core/models/LockedUsersModel';
import { ChangeEmployeePasswordModel } from '../../../../../core/models/changeEmployeePasswordModel';
import { ToDoService } from '../../to-do.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-user-password',
  templateUrl: './user-password.component.html',
  styleUrls: ['./user-password.component.scss'],
  providers: [MessageService]
})
export class UserPasswordComponent implements OnInit {
  @Input() lockUser: LockedUsersModel;
  changeEmployeePassword: ChangeEmployeePasswordModel;
  isLocked: boolean;
  newPassword: string;
  confirmPassword: string;
  validationErrors: string[] = [];
  messageArray: string[];
  responseMsg: string;
  @Output() responseHeader = new EventEmitter<string>();

  constructor(private toDoService: ToDoService,
              private messageService: MessageService) {

  }

  ngOnInit(): void {
    this.isLocked = true;
  }

  savePassword() {
    this.changeEmployeePassword = new ChangeEmployeePasswordModel();
    this.changeEmployeePassword.UserID = this.lockUser.userID;
    this.changeEmployeePassword.FullName = this.lockUser.firstName + " " + this.lockUser.lastName;
    this.changeEmployeePassword.IsLocked = this.isLocked;
    this.changeEmployeePassword.MemberID = this.lockUser.memberID;
    this.changeEmployeePassword.Password = this.newPassword ? this.newPassword : "";

    this.messageArray = this.ValidatePassword();

    if (this.messageArray !== undefined) {
      this.validationErrors = this.messageArray;
    } else {
      this.toDoService.putChangeEmployeePassword(this.changeEmployeePassword).subscribe( (response) => {
        if (response. success === true) {
          this.responseHeader.emit("Unlock Account / Change Employee Password was successful");
        } else {
          this.validationErrors.push(response.message);
        }
      });
    }
  }

  cancelPassword(){
    this.responseHeader.emit("Unlock Account / Change Employee Password was canceled");
  }

  ValidatePassword() {
    this.responseMsg = undefined;
    this.validationErrors =  [];
    let messageArray: string[] = [];

    if (this.newPassword !== undefined && this.newPassword !== "") {
      if (this.confirmPassword === undefined || this.confirmPassword === "") {
        if (this.responseMsg === undefined) {
          this.responseMsg = "'Confirm Password' is required."
        } else {
          this.responseMsg = this.responseMsg + ", 'Confirm Password' is required."
        }
      }

      if (this.newPassword !== this.confirmPassword) {
        if (this.responseMsg === undefined) {
          this.responseMsg = "'New Password' and 'Confirm Password' do not match."
        } else {
          this.responseMsg = this.responseMsg + ", 'New Password' and 'Confirm Password' do not match."
        }
      }
    }

    if (this.responseMsg !== undefined && this.responseMsg !== "") {
      messageArray = this.responseMsg.split(',');

      messageArray.forEach((element, index) => {
        if (element === undefined) messageArray.splice(index, 1);
      });

      return messageArray;
    }
  }
}
