export enum UserFeatures {
  None                    = 0,

  IsNewHire               = 1 << 0,
  IsAbsoluteNewHire       = 1 << 1,
  IsNewHireComplete       = 1 << 2,
  ShowAnnualACAEnroll     = 1 << 3,
  ShowCurrentACAEnroll    = 1 << 4,
  ShowNewHireStart        = 1 << 5,
  ShowAnnualWithNewHire   = 1 << 6,
  ShowAnnualOverride      = 1 << 7,

  IsHybrid                = 1 << 8,
  ShowNowMember           = 1 << 9,
  IsTestMember            = 1 << 10,
  IsAfterNewHireStart     = 1 << 11,
  IsBeforeSiteNewHireDate = 1 << 12,
  IsNewHireOverride       = 1 << 13,
  IsReinstatementRehire   = 1 << 14
}
