import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationModalComponent } from '../../../../shared/modals/confirmation-modal/confirmation-modal.component';
import { TwoFaModel } from '../../../../core/models/twoFaModel';
import { ProfileService } from '../profile.service';
import { TwoFaSettings } from 'src/app/core/models/TwoFaSettings';
import { UserTwoFaEnabledRequest } from 'src/app/core/models/userTwoFaEnabledRequest';

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.scss']
})
export class TwoFactorComponent implements OnInit {
  ref: DynamicDialogRef;
  model: TwoFaModel;
  tfaEnabled: boolean;

  originalCellPhone: string;
  responseMessageEmail: string;
  responseMessage: string;
  cellPhone:string;

  tfaOfficeEmailClass: string;
  tfaHomeEmailClass: string;
  tfaCellPhoneClass: string;
  tfaAddress: TwoFaSettings;

  confirmed: string = 'Confirmed';
  unconfirmed: string = 'Unconfirmed';
  unavailable: string = 'Unavailable';

  invalidEmail: boolean;
  showConfirm: boolean;
  alreadyConfirmed: boolean;
  showConfirmMessage: boolean;

  invalidCellPhone: boolean;
  showConfirmMessageCellPhone: boolean;

  constructor(private route: ActivatedRoute,
              private profileService: ProfileService,
              private dialogService: DialogService) { }

  ngOnInit(): void {
    this.model = this.route.snapshot.data['profileTFA'];

    if (this.model.siteRequires2fa ||
        this.model.userEnables2fa) {
      this.tfaEnabled = true;
    }

    if (this.model.officeEmail) {
      this.tfaOfficeEmailClass = this.model.officeEmail.isConfirmed ? this.confirmed : this.unconfirmed;
    } else {
      this.tfaOfficeEmailClass = this.unavailable;
    }

    if (this.model.homeEmail) {
      this.tfaHomeEmailClass = this.model.homeEmail.isConfirmed ? this.confirmed : this.unconfirmed;
    } else {
      this.tfaHomeEmailClass = this.unavailable;
    }

    if (this.model.cellPhone) {
      this.tfaCellPhoneClass = this.model.cellPhone.isConfirmed ? this.confirmed : this.unconfirmed;
    } else {
      this.tfaCellPhoneClass = this.unavailable;
    }

    this.showConfirm = true;
  }

  setUserTwoFactorEnabled() {
    let request = new UserTwoFaEnabledRequest();
    request.enabled = this.tfaEnabled;
    this.profileService.SetUserTwoFactorEnabled(request).subscribe((response) => {
      //Anything to do here?
    });
  }

  saveUserHomeEmail() {
    this.tfaAddress = new TwoFaSettings();
    this.tfaAddress.address = this.model.homeEmail.address;
    this.tfaAddress.addressIsReadOnly = this.model.homeEmail.addressIsReadOnly;
    this.tfaAddress.isConfirmed = this.model.homeEmail.isConfirmed ? true : false;

    this.profileService.SaveEmail(this.tfaAddress).subscribe( (response) => {
      if (response.success) {
          this.showConfirmMessage = true;
          this.alreadyConfirmed = false;
          this.invalidEmail = false;
      } else {
        this.showConfirmMessage = false;
        this.alreadyConfirmed = false;
        this.invalidEmail = true;
      }
    });
  }

  removeUserHomeEmail() {
    if (this.confirmRemove()) {
      this.profileService.RemoveEmail().subscribe( (response) => {
        if (response.success) {
          this.model.homeEmail.address = undefined;
          this.model.homeEmail.isConfirmed = false;
          this.model.homeEmail.addressIsReadOnly = false;
          this.model.homeEmail.confirmMessage = undefined;
        }
      });
    }
  }

  confirmRemove() {
    if (confirm('Are you sure you want to remove this information?')) {
      return true;
    }
  }

  saveUserCellPhone() {
    this.tfaAddress = new TwoFaSettings();
    this.tfaAddress.address = this.model.cellPhone.address;
    this.tfaAddress.isConfirmed = this.model.cellPhone.isConfirmed;
    this.tfaAddress.addressIsReadOnly = this.model.cellPhone.addressIsReadOnly;
    this.tfaAddress.confirmMessage = "";
    this.invalidCellPhone = false;

    this.profileService.BeginSavePhone(this.tfaAddress).subscribe((response) => {
      this.invalidCellPhone = (response.message === "Invalid phone number: MobilePhone") ? true : false
      if (this.invalidCellPhone) {
        this.originalCellPhone = "";
        this.showConfirmMessageCellPhone = true;
      } else {
        if (response.message !== "Mobile Phone Already Confirmed: MobilePhone") {
          this.ref = this.dialogService.open(ConfirmationModalComponent, {data: this.model})
        }
      }
    })

  }

  removeUserCellPhone() {
    if (this.confirmRemove()) {
      this.profileService.RemovePhone().subscribe( (response) => {
        if (response.success) {
          this.model.cellPhone.address = undefined;
          this.model.cellPhone.isConfirmed = false;
          this.model.cellPhone.addressIsReadOnly = false;
          this.model.cellPhone.confirmMessage = undefined;
        }
      });
    }
  }
}
