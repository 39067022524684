import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { Member } from '../../../core/models/login/member';
import { ThoughtLeadershipResponse } from '../../../core/models/thoughtLeadershipResponse';
import { AccountService } from '../../../core/services/account.service';

@Injectable({
  providedIn: 'root'
})
export class ThoughtLeadershipService {
  member: Member;

  constructor(private accountService: AccountService, private http: HttpClient) {
    this.member = this.accountService.getCurrentMember();
  }

  getThoughtLeadershipResponse(): Observable<ThoughtLeadershipResponse> {
    return this.http.get<ThoughtLeadershipResponse>(this.member.apiPath + 'home/dashboard/GetThoughtLeadership').pipe(retry(1));
  }
}
