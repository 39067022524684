import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './shared/errors/not-found/not-found.component';
import { PublicLayoutComponent } from './shared/public-layout/public-layout.component';

const PUBLIC_ROUTES: Routes = [
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', component: NotFoundComponent, pathMatch: 'full' }
];



const routes: Routes = [
  { path: '', component: PublicLayoutComponent, children: PUBLIC_ROUTES }];

    @NgModule({
      imports: [RouterModule.forRoot(routes)],
      exports: [RouterModule]
    })
    export class AppRoutingModule {}
