<article class="home-thought-leadership">
        <div class="myimage">
          <img src="{{imageUrl}}" alt="NEEDS A DESCRIPTION OR TITLE">
        </div>
        <div class="mycontent">
          <span class="status-new">New!</span>
          <h3 class="text-title">{{thoughtLeadershipModel.name}}</h3>
          <p class="text-body">{{thoughtLeadershipModel.description}}</p>
          <p class="link"><a (click)="thoughtLeadership()">Read More</a></p>
        </div>
</article>
