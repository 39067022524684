import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home/home.component';
import { SharedModule } from '../../shared/shared.module';
import { ToDoComponent } from './to-do/to-do.component';
import { LockedUsersModalComponent } from './to-do/modals/locked-users-modal/locked-users-modal.component';
import { UserPasswordComponent } from './to-do/modals/user-password/user-password.component';
import { ThoughtLeadershipComponent } from './thoughtLeadership/thought-leadership/thought-leadership.component';
import { ThoughtLeadershipCardComponent } from './thoughtLeadership/thought-leadership-card.component';


@NgModule({
  declarations: [
    HomeComponent,
    ToDoComponent,
    LockedUsersModalComponent,
    UserPasswordComponent,
    ThoughtLeadershipComponent,
    ThoughtLeadershipCardComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    HomeRoutingModule
  ]
})
export class HomeModule {}
