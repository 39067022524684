import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import { SecureLayoutComponent } from '../../shared/secure-layout/secure-layout.component';
import { HomeComponent } from './home/home.component';
import { ThoughtLeadershipResolverService } from './thoughtLeadership/thought-leadership-resolver.service';
import { ToDosResolver } from './to-do/to-dos-resolver.service';

const SECURE_ROUTES: Routes = [
  { path: 'home', component: HomeComponent, resolve: { toDos: ToDosResolver, thoughts: ThoughtLeadershipResolverService}}
];

const routes: Routes = [{
  path: '',
  component: SecureLayoutComponent,
  runGuardsAndResolvers: 'always',
  canActivate: [AuthGuard],
  children: SECURE_ROUTES
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})


export class HomeRoutingModule { }
