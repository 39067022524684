<div class="content content-two-column-aside-left-fixed">
  <div class="column column-primary">
    <section class="component primary-component">
      <div class="component-heading">
        <h2>{{ fullName }}</h2>
      </div>
      <app-two-factor></app-two-factor>
      <app-change-password></app-change-password>
    </section>
  </div>
  <!-- SIDEBAR NAVIGATION -->
  <aside class="column column-aside">
    <!-- ***** COLUMN -->
    <!-- Stackable Component Containers-->
    <section class="component primary-component sticky">
      <app-left-nav></app-left-nav>
    </section>
  </aside>
</div>
