import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from '../../core/services/account.service';
import { SiteDetailsResponse } from '../../core/models/login/siteDetailsResponse';

@Injectable({
  providedIn: 'root'
})
export class SiteDetailsResolver  {

  constructor(private accountService: AccountService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SiteDetailsResponse>  {
    return this.accountService.getSiteDetails();
  }
}
