    /// <summary>
    /// User provides one or more of the following properties, API uses these to identity a site
    /// It is expected that most - but not all - properties will be null
    /// If a matching site is found, certain site details will be returned
    /// </summary>

    import { MemberFields } from "../memberFields";
    import { SiteDetails } from "./siteDetails";

        export class SiteDetailsResponse {​​​​​
          SiteDetails: SiteDetails;
          Authentication: {};
          MemberFields: MemberFields;
          Success:string;
          Status:string;
          MessageDetails:[];
          FeatureType:string;
          LanguageId:string;
          ApiVersion: string;
          NowMemberAccessID: string;
        }​​​​​


