import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from '../../../environments/environment';
import { BnNgIdleService } from 'bn-ng-idle';
import { IdleUserModalComponent } from '../../shared/modals/idle-user-modal/idle-user-modal.component';


@Injectable({
  providedIn: 'root'
})
export class IdleUserService {
  ref: DynamicDialogRef;
  isRunning: boolean;
  defaultTimeout:number = environment.idleTimeout;
  private sessionSubscription: Subscription;

  constructor(private bnIdle: BnNgIdleService,
              public dialogService: DialogService) {
    this.isRunning = false;
   }

  startWatching(timeOut) {
    if (!this.isRunning) {
      this.isRunning = true;
      this.sessionSubscription = this.bnIdle.startWatching(this.defaultTimeout).subscribe((isTimeout: boolean) => {
        if (isTimeout) {
          this.bnIdle.stopTimer();
          if (this.ref === null || !this.ref) {
            this.ref = this.dialogService.open(IdleUserModalComponent, {});
          }
        }
      })
    }
  }

  stopTimer() {
    if (this.isRunning) {
      this.bnIdle.stopTimer();
      this.isRunning = false;
      if (this.sessionSubscription) {
        this.sessionSubscription.unsubscribe();
      }
      this.ref = null;
    } else {
      console.log("IdleUserService: stopTimer called when timer was not running. Call ignored.");
    }
  }

  resetTimer() {
    if (this.isRunning) {
      this.bnIdle.resetTimer();
      this.ref = null;
    } else {
      console.log("IdleUserService: resetTimer called when timer not running. Calling startWatching instead.");
      this.startWatching(this.defaultTimeout);
    }
  }

  //convert time(total in seconds) to minutes part
  static splitTimeInSecondsToMinute(seconds) {
    if (seconds > 0) {
      return Math.floor(seconds % 3600 / 60);
    }
    return 0;
  }

  //convert time(in total seconds) to seconds part (after the minutes)
  static splitTimeInSecondsToSeconds(seconds) {
    if (seconds > 0) {
      return Math.floor(seconds % 3600 % 60);
    }
    return 0;
  }

}
