export class ChangeEmployeePasswordModel {
  FullName: string;
  MemberID: number;
  UserID: number;
  IsLocked: boolean;
  Password: string;
  MinPasswordLength: number;
  RequireAlphaNumeric: number;
  UserName:string;
  HistoryLimit: number;
}
