import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IdleUserService } from '../../core/services/idle-user.service';
import { environment } from '../../../environments/environment';
import { AccountService } from '../../core/services/account.service';
import { SsoService } from './sso.service';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss']
})
export class SsoComponent implements OnInit {
  jwt: string = '';
  returnUrl: string = '';
  validationErrors: string[] = [];
  timeOut: number;
  enableHrAdmin: boolean;
  hrAdminRoute:string;

  constructor(private ssoService: SsoService,
    private accountService: AccountService,
    private idleUser: IdleUserService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.jwt = this.activatedRoute.snapshot.queryParamMap.get('jwt');
    this.returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl');
    if (this.jwt !== undefined) {
      this.sso();
    }
  }

  sso() {
    this.accountService.clearUserSession();
    this.timeOut = environment.idleTimeout;
    this.idleUser.startWatching(this.timeOut);
    this.ssoService.sso(this.jwt).pipe().subscribe(data => {
        var destination = this.returnUrl ?? "/home";
        this.router.navigateByUrl(destination);
      },
      error => {
        this.router.navigate(['/login']);
      });
  }
}

