<section class="component primary-component">
  <article class="component-content">
    <div class="card">
        <div class="two-factor-auth">
          <h3>Two-Factor Authentication</h3>
          <div *ngIf="!model.siteRequires2fa">
            <p>Enabling two-factor authentication improves the security of your data.</p>
          </div>
          <div class="p-field-checkbox" *ngIf="!model.siteRequires2fa">
            <p-checkbox label="Enable two-factor authentication" [(ngModel)]="tfaEnabled" (onChange)="setUserTwoFactorEnabled()" [binary]="true" inputId="tfaAuthenicate" checkboxIcon="pi pi-check"></p-checkbox>
          </div>
          <div *ngIf="tfaEnabled" class="dep-ben__sec">
            <div *ngIf="tfaEnabled">
              <p>To improve your two-factor authentication experience, please provide and confirm any missing items.</p>
            </div>
            <div *ngIf="tfaEnabled" class="dep-ben__sec-field">
              <span class="p-float-label">
                <input id="officeEmail" type="text" pInputText [readOnly]="model.officeEmail.addressIsReadOnly" [ngModel]="model.officeEmail.address">
                <label for="officeEmail">Work Email</label>
              </span>
            </div>
            <div *ngIf="tfaEnabled" class="dep-ben__btns-sm">
              <div class="tfa-status" [ngClass]="{'is-confirmed': model.officeEmail.isConfirmed === true, 'is-not-confirmed': model.officeEmail.isConfirmed === false, 'no-status': model.officeEmail.address=== '' }">{{tfaOfficeEmailClass}}</div>
              <span>This value is managed by your employer.</span>
            </div>
            <div *ngIf="tfaEnabled" class="dep-ben__sec-field">
              <span class="p-float-label">
                <input id="homeEmail" type="text" pInputText [readOnly]="model.homeEmail.addressIsReadOnly" (ngModelChange)="model.homeEmail.address = $event"    [(ngModel)]="model.homeEmail.address">
                <label for="homeEmail">Home Email</label>
              </span>
            </div>
            <div *ngIf="tfaEnabled" class="dep-ben__btns-sm">
              <div class="tfa-status" [ngClass]="{'is-confirmed': model.homeEmail.isConfirmed === true, 'is-not-confirmed': model.homeEmail.isConfirmed === false, 'no-status': model.homeEmail.address === '' }">{{tfaHomeEmailClass}}</div>
                <p-button  (click)="saveUserHomeEmail()"  [disabled]="!model.homeEmail.address || !showConfirm" label="Confirm" styleClass="p-button-sm" pRipple></p-button>
                <p-button  (click)="removeUserHomeEmail()" [disabled]="!model.homeEmail.address" label="Remove" styleClass="p-button-sm" pRipple></p-button>
              </div>
              <div *ngIf="invalidEmail" class="dep-ben__sec-field">
                <p >Invalid email address: HomeEmail</p>
              </div>
              <div *ngIf="showConfirmMessage" class="dep-ben__sec-field">
                <p> A confirmation email has been sent to {{model.homeEmail.address}} . Please open it and click on the link to confirm receipt. The contact will remain unconfirmed and unavailable for authentication until this is done. </p>
              </div>
              <div *ngIf="alreadyConfirmed" class="dep-ben__sec-field">
                <p> {{responseMessageEmail}} </p>
              </div>
              <div *ngIf="tfaEnabled" class="dep-ben__sec-field">
                <span class="p-float-label">
                  <input id="cellPhone" type="text" [readOnly]="model.cellPhone.addressIsReadOnly" pInputText [(ngModel)]="model.cellPhone.address">
                  <label for="cellPhone">Mobile Number</label>
                </span>
              </div>
              <div *ngIf="tfaEnabled"  class="dep-ben__btns-sm">
                <div class="tfa-status" [ngClass]="{'is-confirmed': model.cellPhone.isConfirmed === true, 'is-not-confirmed': model.cellPhone.isConfirmed === false, 'no-status': model.cellPhone.address=== ''}">{{tfaCellPhoneClass}}</div>
                <p-button (click)="saveUserCellPhone()" label="Confirm" styleClass="p-button-sm" pRipple></p-button>
                <p-button (click)="removeUserCellPhone()" label="Remove" styleClass="p-button-sm" pRipple></p-button>
              </div>
              <div *ngIf="invalidCellPhone" >
                <p>Invalid phone number: MobilePhone</p>
              </div>
              <div *ngIf="showConfirmMessageCellPhone">
                <p> {{responseMessage}} </p>
              </div>
          </div>
        </div>
    </div>
  </article>
</section>
