import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { NavigationResponse } from '../../core/models/navigation-response';
import { AccountService } from '../../core/services/account.service';
import { Member } from "../../core/models/login/member";
import { SitePathsResponse } from '../../core/models/sitePathsResponse';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private member: Member;
  adminPath: string;

  constructor(private http: HttpClient, private accountService: AccountService) {
    this.member = this.accountService.getCurrentMember();
  }

  GetTopNavigation(): Observable<NavigationResponse> {
    const headers = { 'Authorization': `Bearer ${this.member.token}` }
    return this.http.get<NavigationResponse>(this.member.apiPath + 'navigation/top', {'headers': headers}).pipe(retry(1));
    }

  GetLeftNavigation(): Observable<NavigationResponse> {
    const headers = { 'Authorization': `Bearer ${this.member.token}` }
    return this.http.get<NavigationResponse>(this.member.apiPath + 'navigation/left', {'headers': headers}).pipe(retry(1));
  }

  GetSitePaths(): Observable<SitePathsResponse> {
    const headers = { 'Authorization': `Bearer ${this.member.token}` }
    return this.http.get<SitePathsResponse>(this.member.apiPath + 'settings/getSitePaths', { 'headers': headers }).pipe(retry(1));
  }
}
