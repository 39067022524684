<section class="component primary-component">
  <article class="component-content">
    <div class="change-passwords">
        <p-card>

          <h3>Change Password</h3>
          <div class="change-password-form">
            <div class="login-error" *ngIf="validationErrors?.length > 0">
              <span class="text-danger text-wrapper validator">Password change was unsuccessful</span>
              <ul class="text-danger validator">
                <li *ngFor="let error of validationErrors">{{error}}</li>
              </ul>
            </div>
            <span class="p-float-label dep-ben__pass">
              <p-password [(ngModel)]="oldPassword" type="password" aria-required="true" [toggleMask]="true" required autocomplete="false" ></p-password>
              <label for="oldPassword">Old Password</label>
              <div class="input__error__msg">This is a required field</div>
            </span>

            <span class="p-float-label dep-ben__pass">
              <p-password [(ngModel)]="newPassword" type="password" aria-required="true" [toggleMask]="true" required autocomplete="false" ></p-password>
              <label for="newPassword">New Password</label>
              <div class="input__error__msg">This is a required field</div>
            </span>

            <span class="p-float-label dep-ben__pass">
              <p-password [(ngModel)]="confirmPassword" type="password" aria-required="true" [toggleMask]="true" required autocomplete="false" ></p-password>
              <label for="confirmPassword">Confirm Password</label>
              <div class="input__error__msg">This is a required field</div>
            </span>

            <div class="button-container">
                <button (click)="savePassword()" type="submit" pButton label="Save" class="p-button" pRipple></button>
            </div>
          </div>
        </p-card>
    </div>
  </article>
</section>
