export enum UserLinks {
  None                        = 0,

  ShowEnrollmentGuides        = 1 << 0,
  ShowHRKeyContacts           = 1 << 1,
  ShowCalendarOfEvents        = 1 << 2,
  ShowJobPostings             = 1 << 3,
  ShowGeneralPurposeLetters   = 1 << 4,
  ShowMessageBoard            = 1 << 5,
  ShowForms                   = 1 << 6,
  ShowNewsLetters             = 1 << 7,

  ShowExecutiveProfiles       = 1 << 8,
  ShowEmployerProfile         = 1 << 9,
  ShowExternalLinks           = 1 << 10,
  ShowSurveys                 = 1 << 11,
  ShowPlansAndPolicies        = 1 << 12,
  ShowCustomResources         = 1 << 13,
  ShowOneClickTabs            = 1 << 14,
  ShowEmployerLetters         = 1 << 15,

  ShowLifeEventConfirm        = 1 << 16,
  ShowAnnualConfirm           = 1 << 17,
  ShowPasswordChange          = 1 << 18,
  ShowAddressChange           = 1 << 19,
  ShowEmergencyContacts       = 1 << 20,
  ShowTaxesChange             = 1 << 21,
  ShowDSSRetirement           = 1 << 22,
  ShowDSSBenefit              = 1 << 23,

  ShowAvatarQA                = 1 << 24,
  ShowRequestedDocuments      = 1 << 25,
  ShowBenefitElection         = 1 << 26,
  ShowBenefitInfo             = 1 << 27,
  ShowBenefitEECosts          = 1 << 28,
  ShowBenefitPayCheck         = 1 << 29,
  ShowBenefitERCosts          = 1 << 30,
  ShowBenefitComp             = 1 << 31,

  ShowBenefitCompStmt         = 1 << 32,
  ShowBenefitPTO              = 1 << 33,
  ShowPendingBenefits         = 1 << 34,
  Show2faSettings             = 1 << 35,
  ShowGKSSO                   = 1 << 36,
  ShowTopNav                  = 1 << 37,
  ShowBenefitCompStmtNow      = 1 << 38,
  ShowEBCFlex                 = 1 << 39,

  ShowFAQs                    = 1 << 40
}
