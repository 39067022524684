export enum HRLinks {
  None = 0,
  ShowSiteFiles = 1 << 0,
  ShowSiteSetupPaytypes = 1 << 1,
  ShowPlansAndPolicies = 1 << 2,
  ShowEmployeeRecords = 1 << 3,
  ShowMessagingUtility = 1 << 4,
  ShowSelfServeReports = 1 << 5,
  ShowBenefitManagement = 1 << 6,
  ShowContentBuilder = 1 << 7,
  ShowDependentTypes = 1 << 8,
  ShowBenefitStatements = 1 << 9,
  ShowPaytypes = 1 << 10,
  ShowDataManagementLogs = 1 << 11,
  ShowEnrollmentEmails = 1 << 12,
  ShowPendingManagement = 1 << 13,
  ShowDataImport = 1 << 14,
  ShowRequiredInformation = 1 << 15,
  ShowSurveys = 1 << 16,
  ShowEnrollmentInstructions = 1 << 17,
  ShowQuickLinks = 1 << 18,
  ShowEmployeeSalaries = 1 << 19,
  GeneralSiteInfoAllowEdit = 1 << 20,
  ShowEnrollmentLinks = 1 << 21,
  EmployeeManagementShowAllUsers = 1 << 22,
  EmployeeManagementAllowEdit = 1 << 23,
  ShowUnifyHR = 1 << 24,
  All = ~(-1 << 25)
}
