<section class="component primary-component" *ngIf="toDoList">
  <article class="component-content">
    <section class="component-heading">
      <h2>{{toDoType}} ({{toDoCount}})</h2>
    </section>
    <p-card>
      <div class="grid">
        <div class="grid"  *ngIf="!toDoCount">You're all caught up!</div>
        <div class="col-12">
          <div class="grid" *ngIf="toDoCount">
            <div class="col-6" *ngIf="linkList.Alerts.length > 0">
              <div class="dashboard-todos">
                <div class="dashboard-todos__item"  *ngFor="let alert of linkList.Alerts">
                  <div class="dashboard-todos__count" *ngIf="alert.alertType != 0 && alert.displayType < displayType"> {{alert.count}}</div>
                  <div class="dashboard-todos__title" *ngIf="alert.alertType != 0 && alert.displayType < displayType"> &nbsp; {{alert.header}}</div>
                  <a (click)="goToAdmin(alert.linkURL)"><i class="far fa-external-link" *ngIf="alert.alertType == 1 && alert.displayType < displayType"></i></a>
                  <a (click)="displayLockedEmp()"><i class="far fa-lock" *ngIf="alert.alertType == 3 && alert.displayType < displayType"></i></a>
                </div>
              </div>
              <a (click)="setViewAll()" *ngIf="displayLink">{{displayTitle}}</a>
            </div>
            <div class="col-6" *ngIf="downloadList.Alerts.length > 0">
              <div class="dashboard-todos">
                <div class="dashboard-todos__item"  *ngFor="let alert of downloadList.Alerts">
                  <div class="dashboard-todos__count" *ngIf="alert.alertType != 0 && alert.displayType < displayType"> {{alert.count}}</div>
                  <div class="dashboard-todos__title" *ngIf="alert.alertType != 0 && alert.displayType < displayType"> &nbsp; {{alert.header}}</div>
                  <a (click)="getAlertDownload(alert.downloadName)"><i class="far fa-download" *ngIf="alert.alertType == 2 && alert.displayType < displayType"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-card>
  </article>
</section>
<section class="component primary-component" *ngIf="oePeriodList">
  <article class="component-content">
    <section class="component-heading">
      <h2>{{toDoType}} ({{toDoCount}})</h2>
    </section>
    <p-card>
      <div class="grid">

          <div class="grid"  *ngIf="!toDoCount">You're all caught up!</div>

          <div *ngIf="toDoCount && oePeriodList.oePeriods.length > 1">
            <select [(ngModel)]="currentOePeriod" (change)="oePeriodChanged(currentOePeriod)">
              <option *ngFor="let oeperiod of oePeriodList.oePeriods" [value]="oeperiod.enrollPeriodID">{{oeperiod.name}}</option>
            </select>
          </div>

          <div class="col-12">
            <br>
          <div class="grid" *ngIf="toDoCount">
            <div class="col-6" *ngIf="linkOepList.enrollPeriodAlerts.length > 0">
              <div class="dashboard-todos">
                  <div class="dashboard-todos__item"  *ngFor="let epa of linkOepList.enrollPeriodAlerts" >
                    <div class="dashboard-todos__count" *ngIf="epa.epaType != 0 && epa.displayEpaType <= displayEpaType"> {{epa.count}}</div>
                    <div class="dashboard-todos__title" *ngIf="epa.epaType != 0 && epa.displayEpaType <= displayEpaType"> &nbsp; {{epa.header}}</div>
                    <a (click)="goToAdmin(epa.linkURL)"><i class="far fa-external-link" *ngIf="epa.epaType == 1 && epa.displayEpaType <= displayEpaType"></i></a>
                  </div>
              </div>
              <a (click)="setViewEpaAll()" *ngIf="displayEpaType > 1">{{displayEpaTitle}}</a>
            </div>
            <div class="col-6" *ngIf="downloadOepList.enrollPeriodAlerts.length > 0">
              <div class="dashboard-todos">
                <div class="dashboard-todos__item"  *ngFor="let epa of downloadOepList.enrollPeriodAlerts" >
                  <div class="dashboard-todos__count" *ngIf="epa.epaType != 0 && epa.displayEpaType <= displayEpaType"> {{epa.count}}</div>
                  <div class="dashboard-todos__title" *ngIf="epa.epaType != 0 && epa.displayEpaType <= displayEpaType"> &nbsp; {{epa.header}}</div>
                  <a (click)="getAlertDownload(epa.downloadName)"><i class="far fa-download" *ngIf="epa.epaType == 2 && epa.displayEpaType <= displayEpaType"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-card>
  </article>
</section>

<app-goto-admin [hrAdminRoute]="hrAdminRoute" *ngIf="enableHrAdmin"></app-goto-admin>
