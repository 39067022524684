export class ThoughtLeadershipResponse {
  success: boolean;
  key: number;
  message: string;
  data: ThoughtLeadershipModel[];
};

export class ThoughtLeadershipModel {
  name: string;
  date: Date;
  url: string;
  resourceType: string;
  tileImageUrl: string;
  description: string;
  tags: ThoughtLeadershipTag[];
};

export class ThoughtLeadershipTag {
  name: string;
  navigationName: string;
  url: string;
  isOperation: false;
  tagGUID: string;
};