import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ToDoResponse } from '../../../core/models/toDosResponse';
import { ToDoService } from './to-do.service';

@Injectable({
  providedIn: 'root'
})
export class ToDosResolver  {

  constructor(private toDoService: ToDoService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ToDoResponse> {
    return this.toDoService.getAlerts();
  }
}
