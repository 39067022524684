<section class="content content-two-column-aside-left-fixed">
  <section class="column column-primary">
    <section class="component pagetitle-component">
      <h1>Components</h1>
    </section>
    <section class="component primary-component">
      <section class="component-heading">
        <h2>Component heading</h2>
      </section>
      <article class="component-content">
        <p-tabView>
          <p-tabPanel header="Form elements">
            Content 1
          </p-tabPanel>
          <p-tabPanel header="Chips">
            <div class="grid align-content-center">
              <div class="col-12">
                <p-chip label="Thriller" [removable]="true"></p-chip>&nbsp;
                <p-chip label="Thriller" [removable]="true"></p-chip>&nbsp;
                <p-chip label="AFD" [removable]="true"></p-chip>&nbsp;
                <p-chip label="Eruption" [removable]="true"></p-chip>
              </div>
              <div class="col-12">
                <p-chip label="That Guy" image="https://www.placecage.com/200/200" [removable]="true"></p-chip>&nbsp;
                <p-chip label="That Guy" image="https://www.placecage.com/220/220" [removable]="true"></p-chip>&nbsp;
                <p-chip label="That Guy" image="https://www.placecage.com/230/230" [removable]="true"></p-chip>&nbsp;
                <p-chip label="That Guy" image="https://www.placecage.com/210/210" [removable]="true"></p-chip>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Header 3">
            Content 3
          </p-tabPanel>
        </p-tabView>
      </article>
    </section>
    <section class="component primary-component">
      <article class="component-content">
        <div class="grid">
          <div class="col-12">
            <p-chip label="Text Only"></p-chip>
          </div>
          <div class="col-12">
            <p-chip label="Text with icon" icon="pi pi-check"></p-chip>
          </div>
        </div>
      </article>
    </section>
    <section class="component primary-component">
      <section class="component-heading">
        <h2>Form Elements</h2>
      </section>
      <article class="component-content">
        <p-card>
          <div class="grid">
            <div class="col-12">
              <p-inputSwitch [(ngModel)]="inputSwitch" ngDefaultControl></p-inputSwitch>
            </div>
            <div class="col-12 md:col-10 lg:col-11 xl:col-11">
              <p-slider [(ngModel)]="val" name="pslider" ngDefaultControl></p-slider>
            </div>
            <div class="col-12 md:col-2 lg:col-1 xl:col-1">
              <div>{{val}}</div>
            </div>
            <div class="col-12">
              <p-selectButton [options]="stateOptions" name="selectBtn" [(ngModel)]="value1" optionLabel="label" optionValue="value" ngDefaultControl></p-selectButton>
            </div>
          </div>
          <form [formGroup]='form' (ngSubmit)="onSubmit()" autocomplete="off">
            <div class="grid">
              <div class="col-12 md:col-4 lg:col-3 xl:col-2">
                <p-avatar label="P"></p-avatar>
              </div>
              <div class="col-12 md:col-4 lg:col-3 xl:col-2">
                <p-avatar icon="pi pi-search"></p-avatar>
              </div>
              <div class="col-12 md:col-4 lg:col-3 xl:col-2">
                <p-avatar label="P" shape="circle"></p-avatar>
              </div>
              <div class="col-12 md:col-4 lg:col-3 xl:col-2">
                <p-avatar image="https://www.placecage.com/200/200" size="xlarge"></p-avatar>
              </div>
              <div class="col-12 md:col-4 lg:col-3 xl:col-2">
                <p-avatar image="https://www.placecage.com/150/150" shape="circle" size="xlarge"></p-avatar>
              </div>
              <div class="col-12">
                <p-divider align="center"><span class="p-tag">Divider Title</span></p-divider>
              </div>
              <div class="col-12 md:col-2 lg:col-2 xl:col-2">
                <p-badge value="2"></p-badge>
              </div>
              <div class="col-12 md:col-2 lg:col-2 xl:col-2">
                <p-badge value="2" severity="success"></p-badge>
              </div>
              <div class="col-12 md:col-2 lg:col-2 xl:col-2">
                <p-badge value="2" severity="warning"></p-badge>
              </div>
              <div class="col-12 md:col-2 lg:col-2 xl:col-2">
                <p-badge value="2" severity="info"></p-badge>
              </div>
              <div class="col-12 md:col-2 lg:col-2 xl:col-2">
                <p-badge value="2" severity="danger"></p-badge>
              </div>
              <div class="col-12">
                <p-divider align="right"><span class="p-tag">Divider Title</span></p-divider>
              </div>
              <div class="col-12">
                <p-divider><span class="p-tag">Divider Title</span></p-divider>
              </div>
              <div class="col-12">
                <p-divider><span class="p-tag">Divider Title</span></p-divider>
              </div>
              <div class="col-12">
                <p-divider><span class="p-tag">Divider Title</span></p-divider>
              </div>
              <div class="col-12">
                <p-divider><span class="p-tag">Divider Title</span></p-divider>
              </div>
              <div class="col-12">
                <div class="p-checkbox-wrapper">
                  <p-checkbox name="chkbx2" id="chkbx2" label="checkbox 2" value="val1" formControlName="chkbx2" [required]="chkbx2Req == true" ngDefaultControl></p-checkbox>
                  <i class="far fa-asterisk" *ngIf="chkbx2Req == true"></i>
                  <p-message data-position="left" severity="error" text="Field is required" [@errorState]="chkbx2Req == true && submitted &&  getform.chkbx2.errors || getform.chkbx2.touched && getform.chkbx2.errors?.required ? 'visible' : 'hidden'"></p-message>
                </div>
              </div>
              <div class="col-12">
                <p-divider><span class="p-tag">Divider Title</span></p-divider>
              </div>
              <div class="col-12">
                <p-checkbox name="chkbx1" id="chkbx1" label="checkbox 1" value="val1" formControlName="chkbx1" [required]="chkbx1Req == true" ngDefaultControl></p-checkbox>
              </div>
              <div class="col-12">
                <p-divider><span class="p-tag">Divider Title</span></p-divider>
              </div>
              <div class="col-12">
                <p-divider><span class="p-tag">Divider Title</span></p-divider>
              </div>
              <div class="col-12 md:col-12 lg:col-6 xl:col-3">
                <div class="p-float-label">
                  <p-dropdown id="citiesDropDown" name="citiesDropDown" [options]="cities" formControlName="citiesDropDown" pInputText [required]="ddReq == true" [ngClass]="{ 'ng-dirty': submitted && getform.citiesDropDown.errors || getform.citiesDropDown.touched && getform.citiesDropDown.errors?.required}" optionLabel="name" ngDefaultControl></p-dropdown><label for="citiesDropDown">Cities Drop Down <i class="far fa-asterisk" *ngIf="ddReq == true"></i></label>
                  <p-message severity="error" text="Field is required" [@errorState]="ddReq == true && submitted &&  getform.citiesDropDown.errors || getform.citiesDropDown.touched && getform.citiesDropDown.errors?.required ? 'visible' : 'hidden'"></p-message>
                </div>
              </div>
              <div class="col-12 md:col-12 lg:col-6 xl:col-3">
                <div class="p-float-label">
                  <input type="text" id="inputtext" formControlName="inputtext" pInputText [required]="inputText1Req == true" [ngClass]="{ 'ng-dirty': submitted && getform.inputtext.errors || getform.inputtext.touched && getform.inputtext.errors?.required}">
                  <label for="inputtext">Text Input
                    <i class="far fa-asterisk" *ngIf="inputText1Req == true"></i>
                  </label>
                  <p-message severity="error" text="Field is required" [@errorState]="inputText1Req == true && submitted && getform.inputtext.errors || getform.inputtext.touched && getform.inputtext.errors?.required? 'visible' : 'hidden'"></p-message>
                </div>
              </div>
              <div class="col-12 md:col-12 lg:col-6 xl:col-3">
                <p-divider layout="vertical"> <span class="p-tag">Divider Title</span></p-divider>
              </div>
              <div class="col-12 md:col-12 lg:col-6 xl:col-3">
                <div class="p-float-label">
                  <p-calendar id="cal1" name="cal1" ngDefaultControl formControlName="cal1" [required]="cal1Req == true" [ngClass]="{ 'ng-dirty': submitted && getform.cal1.errors || getform.cal1.touched && getform.cal1.errors?.required}">
                    ></p-calendar>
                  <label for="cal1">Calendar <i class="far fa-asterisk" *ngIf="cal1Req == true"></i>
                  </label>
                  <p-message severity="error" text="Field is required" [@errorState]="cal1Req == true && submitted && getform.cal1.errors || getform.cal1.touched && getform.cal1.errors?.required ? 'visible' : 'hidden'"></p-message>
                </div>
              </div>
              <div class="col-12">
                <p-divider><span class="p-tag">Divider Title</span></p-divider>
              </div>
              <div class="col-12">
                <button pButton type="submit" label="Click"></button>
                <button pButton type="submit" label="Click" disabled></button>
              </div>
            </div>
          </form>
        </p-card>
      </article>
    </section>
    <section class="component primary-component">
      <section class="component-heading">
        <h2>Steps</h2>
      </section>
      <article class="component-content">
        <p-card>
          <div class="grid">
            <div class="col-12">
              <p-steps [model]="items"></p-steps>
            </div>
            <div class="col-12">
            </div>
          </div>
        </p-card>
      </article>
    </section>
    <section class="component primary-component">
      <section class="component-heading">
        <h2>Dynamic Dialog</h2>
      </section>
      <article class="component-content">
        <p-card>
          <div class="grid">
            <div class="col-12">
            </div>
          </div>
        </p-card>
      </article>
    </section>
    <section class="component primary-component">
      <article class="component-content">
        <p-paginator [rows]="10" [totalRecords]="100"></p-paginator>
      </article>
    </section>
  </section>
  <!-- SIDEBAR NAVIGATION -->
  <aside class="column column-aside">
    <!-- ***** COLUMN -->
    <!-- Stackable Component Containers-->
    <section class="component primary-component sticky">
      <app-left-nav></app-left-nav>
    </section>
  </aside>
</section>
