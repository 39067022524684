<div class="grid flex align-content-center">
  <div class="col-12">
    <div class="grid">
      <div class="col-12">
        <h3><b>Confirm Mobile Phone Number</b></h3>
      </div>
      <div class="col-12 text-wrapper">
        <p>A confirmation code has been sent by text message to your cell phone. Please enter the code in the box below the disclaimer and click 'Confirm' to confirm that the cell phone number is correct.</p>
      </div>
      <div class="col-12 text-wrapper">
        <p>By confirming the cell phone, you agree to the Terms Of Use for SmartBen text messaging.</p>
      </div>
      <div class="grid flex align-content-center">
        <div class="col-12">
          <div class="grid">
            <div class="col-12 text-wrapper">
              <div class="col-12 md:col-12 lg:col-12 xl:col-12  flex align-content-center justify-content-center ">
                <h3><b>Terms Of Use</b></h3>
              </div>
              <div class="col-12">
                <p>By opting in you are allowing SmartBen to deliver information to you via SMS. SmartBen is requesting this information to streamline and secure your enrollment experience. You will receive a message containing a login token on subsequent login attempts. SmartBen may also deliver important notifications regarding your benefits via SMS. Standard message and data rates apply for any messages sent to you or from you to us. If you have any questions about your text plan or data plan, it is best to contact your wireless provider.</p>
                <p>This service is supported on the following carriers: <b>AT&T, Boost Mobile, T-Mobile, Metro PCS, Verizon Wireless, Sprint, U.S. Cellular, Nextel and Virgin Mobile.</b></p>
                <p>Note for T-Mobile users: T-Mobile is not liable for delayed or undelivered messages.</p>
                <p>You can cancel SMS delivery at any time. Reply “STOP” to the service to discontinue.</p>
                <p>For support questions or further information, please contact us via info@smartben.com. Click here to view our <a href="https://alight.com/hodges-mace-privacy-policy">privacy policy.</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-12 lg:col-8">
        <span class="p-float-label">
          <input id="cellPhone" type="text" (keypress)="keyPressNumbers($event)" pInputText ng-minlength=6 ng-maxlength=6 (ngModelChange)="sixDigitCode = $event" [(ngModel)]="sixDigitCode">
          <label for="cellPhone">Confirmation Code (6-digit code)</label>
        </span>
      </div>
      <div class="col-12">
        <p-button icon="pi pi-check" pRipple type="button" label="Confirm" (click)="confirm();" [disabled]="sixDigitCode.length != 6" styleClass="p-button-rounded"></p-button>&nbsp; &nbsp;
        <p-button                    pRipple type="button" label="Cancel" (click)="cancel()" styleClass="p-button-plain"></p-button>
      </div>

      <div class="col-12 md:col-12 lg:col-8" *ngIf="showConfirmMessageCellPhone">
        <span [ngStyle]="{'color': 'red'}">
            {{responseMessage}}
        </span>
      </div>


    </div>
  </div>
</div>
