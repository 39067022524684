import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import { SecureLayoutComponent } from '../../shared/secure-layout/secure-layout.component';
import { SsoComponent } from './sso.component';

const SECURE_ROUTES: Routes = [
  { path: 'sso', component: SsoComponent}
];

const routes: Routes = [{
  path: '',
  component: SecureLayoutComponent,
  runGuardsAndResolvers: 'always',
  canActivate: [AuthGuard],
  children: SECURE_ROUTES
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SsoRoutingModule { }
