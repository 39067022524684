import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TwoFaSettings } from '../../../core/models/TwoFaSettings';
import { ProfileService } from '../../left-nav/profile/profile.service';
import { TwoFaModel } from '../../../core/models/twoFaModel';
import { throwError } from 'rxjs/internal/observable/throwError';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @Input() model: TwoFaModel;
  responseMessage: string;
  sixDigitCode: string;
  isAvailCellPhone: boolean;
  showConfirmCellPhone: boolean;
  originalCellPhone: string;
  showConfirmMessageCellPhone: boolean;
  invalidCellPhone: boolean;
  invalidConfirmation: boolean;
  content: TwoFaModel;
  tfaAddress: TwoFaSettings;

  constructor(public ref: DynamicDialogRef,
              public config: DynamicDialogConfig,
              private profileService: ProfileService) {

    this.isAvailCellPhone = false;
    this.showConfirmCellPhone = true;
    this.showConfirmMessageCellPhone = false;
    this.invalidCellPhone = false;
    this.invalidConfirmation = false;
    this.sixDigitCode = "";
    this.content = config.data;
  }

  ngOnInit(): void {
  }

  confirm() {
    this.tfaAddress = new TwoFaSettings();
    this.tfaAddress.address = this.content.cellPhone.address;
    this.tfaAddress.isConfirmed = this.content.cellPhone.isConfirmed;
    this.tfaAddress.addressIsReadOnly = this.content.cellPhone.addressIsReadOnly;
    this.tfaAddress.confirmMessage = "";
    this.tfaAddress.actionValue = this.sixDigitCode;
    this.invalidCellPhone = false;
    this.profileService.EndSavePhone(this.tfaAddress).subscribe((response) => {
      console.log(response.message);
      this.invalidConfirmation = (response.message === "Cell phone number confirmation failed. Please check the phone number and retry the confirmation.") ? true : false
      if (this.invalidConfirmation) {
        this.showConfirmMessageCellPhone = true; // added...
        this.responseMessage = response.message;
        this.originalCellPhone = "";
        this.showConfirmCellPhone = true;
      }
      else {
        this.showConfirmMessageCellPhone = true;
        this.responseMessage = response.message;
        this.showConfirmCellPhone = false;
        this.isAvailCellPhone = true;

        this.originalCellPhone = this.model.cellPhone.address;

        if (this.ref) {
          this.ref.close();
        }

      }
    }, error => {
      console.log(error);
      return throwError(error);
    });

  }

  cancel() {
    if (this.ref) {
      this.ref.close();
    }
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else {
      return true;
    }
  }
}
